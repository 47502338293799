import { getAccessToken } from '../cookiesToken';

/**
 * Axios interceptor for request
 * Always include access_token which is saved on localStorage
 */
export function callback(config) {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

/**
 * When get error, reject it
 */
export function error(error) {
  Promise.reject(error);
}
