export default {
  getProfile() {
    return this.get('/oauth/owner/data/profile');
  },
  getNotification() {
    return this.get('/oauth/owner/data/notification');
  },
  getOwnerChargingInfo() {
    return this.get('/oauth/owner/information');
  },
  getBannerEvent() {
    return this.get('/oauth/events');
  },
  getDashboardInformation() {
    return this.get('/pms_api/v3/owner/dashboard/information-card');
  },
};
