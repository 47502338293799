export const state = () => ({
  // Auto BBK InfoBar data
  autoBbkInfobar: {},

  isLoading: false,
});

export const mutations = {
  setAutoBbkInfobar(state, data) {
    state.autoBbkInfobar = data;
  },
  updateLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const actions = {
  getKosManagementInfobar({ commit }) {
    commit('updateLoading', true);
    return this.$api.getKosManagementInfobar().then(res => {
      commit('setAutoBbkInfobar', res.data.data);
      commit('updateLoading', false);
    });
  },

  updateAutoBbkInfobar({ commit, state }, params) {
    const updatedState = {};

    Object.entries(state.autoBbkInfobar).forEach(([key, value]) => {
      updatedState[key] = key === params.key ? params.value : value;
    });

    commit('setAutoBbkInfobar', updatedState);
  },
};
