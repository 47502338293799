export default function({ store, route, app }) {
  if (app.$device.isMobile) return;

  store.commit('desktop/setHideSidebar', false);
  store.commit('desktop/setHideHeader', false);
  store.commit('desktop/setHideNotifChat', false);
  store.commit('desktop/setBodyBackground', '');
  store.commit('desktop/setWrapperBodyNoPadding', false);

  route.meta.forEach(meta => {
    if (meta.hideSidebar) {
      store.commit('desktop/setHideSidebar', true);
    }

    if (meta.hideHeader) {
      store.commit('desktop/setHideHeader', true);
    }

    if (meta.hideNotifChat) {
      store.commit('desktop/setHideNotifChat', true);
    }

    if (meta.desktopBodyBackground) {
      store.commit('desktop/setBodyBackground', meta.desktopBodyBackground);
    }

    if (meta.wrapperBodyNoPadding) {
      store.commit('desktop/setWrapperBodyNoPadding', true);
    }
  });
}
