export default {
  getPrimeFaq() {
    return this.get('/pms_api/v3/prime/faq');
  },
  submitPayPrime(payload) {
    return this.post('/pms_api/v3/prime/order', payload);
  },
  getPackagePeriode(subdistrictId) {
    return this.get(`/pms_api/v3/prime/package/${subdistrictId}`);
  },
  getPrimeHistoryList(params) {
    return this.get('/pms_api/v3/prime/order', { params });
  },
};
