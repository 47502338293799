import md5 from 'md5';
import { setToken } from '../service/cookiesToken';

const params = {
  grant_type: 'password',
  client_id: process.env.OAUTH_CLIENT_ID,
  client_secret: process.env.OAUTH_CLIENT_SECRET,
  // Scope: '*', temporary comment. Will be checked on BE
};

export default {
  issueToken({ password, username }) {
    return this.post('/oauth/token', {
      ...params,
      username,
      password: md5(password),
    }).then(res => {
      const {
        data: { access_token, refresh_token },
      } = res;
      setToken({ access_token, refresh_token });
      return res;
    });
  },
  refreshToken(data) {
    return this.post('/oauth/token', {
      ...params,
      ...data,
      grant_type: 'refresh_token',
    });
  },
  getUser() {
    return this.post('/oauth/user');
  },
  setPassword(data) {
    return this.post('/oauth/owner/password/set', data);
  },
  requestCode() {
    return this.post('/oauth/owner/verification/code');
  },
  submitVerificationCode(data) {
    return this.post('/oauth/owner/verification/store', data);
  },
};
