export const getSessionId = () => {
  if (localStorage && localStorage.getItem('MOE_DATA')) {
    const parsedClientData = JSON.parse(localStorage.getItem('MOE_DATA'));
    return parsedClientData?.SESSION?.sessionKey;
  }
  return null;
};

// Get device_id from cookies first, if not exist, find on localStorage
export const getDeviceId = () => {
  if (Cookies && Cookies.get('USER_DATA')) {
    const parsedClientData = JSON.parse(Cookies.get('USER_DATA'));
    return parsedClientData?.deviceUuid;
  }

  if (localStorage && localStorage.getItem('MOE_DATA')) {
    const parsedClientData = JSON.parse(localStorage.getItem('MOE_DATA'));
    return parsedClientData?.USER_DATA?.deviceUuid;
  }

  return null;
};
