import _find from 'lodash/find';

export const state = () => ({
  kosList: [],
  selectedKosId: 0,
  bookings: [],
  bookingStatusFilter: [0],
  bookingListCount: 0,
  bookingListOffset: 0,
  bookingListHasMore: true,
  bbkStatus: null,
});

export const mutations = {
  setKosList(state, kosList) {
    state.kosList = [...kosList];
  },
  appendKosList(state, kos) {
    const tempKostList = [...state.kosList];

    if (!_find(tempKostList, { id: kos.id })) {
      tempKostList.push(kos);
    }

    state.kosList = [...tempKostList];
  },
  setSelectedKosId(state, id) {
    state.selectedKosId = id;
  },
  setBookings(state, bookings) {
    state.bookings = [...bookings];
  },
  appendBookings(state, bookings) {
    state.bookings = [...state.bookings, ...bookings];
  },
  setBookingListCount(state, count) {
    state.bookingListCount = count;
  },
  setBookingListOffset(state, total) {
    state.bookingListOffset = total;
  },
  setBookingListHasMore(state, hasMore) {
    state.bookingListHasMore = !!hasMore;
  },
  setBookingStatusFilter(state, statusFilter) {
    state.bookingStatusFilter = [...statusFilter];
  },
  setBbkStatus(state, bbkStatus) {
    state.bbkStatus = bbkStatus;
  },
};

export const getters = {
  bbkStatusByPrio({ bbkStatus }) {
    if (bbkStatus?.approve > 0) {
      return 'approve';
    } else if (bbkStatus?.waiting > 0) {
      return 'waiting';
    }

    return '';
  },
};

export const actions = {
  resetFetchedData({ commit }) {
    commit('setKosList', []);
    commit('setBookings', []);
    commit('setSelectedKosId', 0);
    commit('setBookingListCount', 0);
    commit('setBookingListOffset', 0);
    commit('setBookingListHasMore', true);
  },
};
