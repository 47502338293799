export const appendRooms = (rooms, newRooms) => {
  const roomMap = {};

  rooms.forEach(kos => {
    roomMap[kos.songId] = kos.songId;
  });

  const filteredNewRooms = newRooms.filter(room => {
    if (roomMap[room.songId]) return false;

    roomMap[room.songId] = room.songId;
    return true;
  });

  return [...rooms, ...filteredNewRooms];
};
