import wrapApiWith from '~/api';

export default ({ $axios, app }, inject) => {
  /**
   * Wrap api interfaces with axios
   * Send app as well for binding it to all api interfaces
   * Inject to context as $api
   */
  inject('api', wrapApiWith($axios, app));
  inject('mamikosUrl', process.env.MAMIKOS_URL);
};
