export const state = () => ({
  isLoading: false,
  isLoadingFetchMore: false,
  reviewList: [],
  hasMore: false,
  currentOffset: 0,
});

export const mutations = {
  setIsLoading(state, data = false) {
    state.isLoading = data;
  },
  setIsLoadingFetchMore(state, data = false) {
    state.isLoadingFetchMore = data;
  },
  setReviewList(state, data = []) {
    state.reviewList = data;
  },
  setHasMore(state, data = false) {
    state.hasMore = data;
  },
  setCurrentOffset(state, data = 0) {
    state.currentOffset = data;
  },
};

export const actions = {
  fetchReviews({ commit, state }, limit) {
    const selectedLoadingMutation =
      state.currentOffset > 0 ? 'setIsLoadingFetchMore' : 'setIsLoading';

    commit(selectedLoadingMutation, true);

    return this.$api
      .getPropertyReviews({ limit, offset: state.currentOffset })
      .then(response => {
        if (response.data.status) {
          const newList = state.reviewList.concat(response.data.data);

          commit('setReviewList', newList);
          commit('setHasMore', response.data.pagination.has_more);
          commit('setCurrentOffset', state.currentOffset + limit);
        }

        commit(selectedLoadingMutation, false);
      });
  },
  resetReviewItems({ commit }) {
    commit('setReviewList', []);
    commit('setHasMore', false);
    commit('setCurrentOffset', 0);
  },
};
