const getStateFromLocalStorage = (localStorageKey = '', defaultValue = {}) => {
  const inStorage = localStorage.getItem(localStorageKey);
  if (inStorage) {
    return JSON.parse(inStorage);
  }
  localStorage.setItem(localStorageKey, JSON.stringify(defaultValue));
  return defaultValue;
};

export const state = () => ({
  mamipoinCard: getStateFromLocalStorage('mamipoin/mamipoinCard', {
    hasViewedOnboarding: false,
  }),
  mamipoinPage: getStateFromLocalStorage('mamipoin/mamipoinPage', {
    hasViewedOnboarding: false,
    currentOnboardingStep: 'mamipoinCard',
  }),
  rewardsPage: getStateFromLocalStorage('mamipoin/rewardsPage', {
    hasViewedOnboarding: false,
    currentOnboardingStep: 'pointCount',
  }),
  redeemDetailPage: {
    prevPath: {
      name: 'mamipoin',
      path: '/mamipoin',
    },
  },
});

export const mutations = {
  setMamipoinCard(state, mamipoinCard = {}) {
    state.mamipoinCard = { ...state.mamipoinCard, ...mamipoinCard };
    localStorage.setItem('mamipoin/mamipoinCard', JSON.stringify(mamipoinCard));
  },
  setMamipoinPage(state, mamipoinPage = {}) {
    state.mamipoinPage = { ...state.mamipoinPage, ...mamipoinPage };
    localStorage.setItem('mamipoin/mamipoinPage', JSON.stringify(mamipoinPage));
  },
  setRewardsPage(state, rewardsPage = {}) {
    state.rewardsPage = { ...state.rewardsPage, ...rewardsPage };
    localStorage.setItem('mamipoin/rewardsPage', JSON.stringify(rewardsPage));
  },
  setRedeemDetailPage(state, redeemDetailPage = {}) {
    state.redeemDetailPage = { ...state.redeemDetailPage, ...redeemDetailPage };
  },
};
