import Cookies from 'js-cookie';

export const state = () => ({
  isBannerDisplayed: Cookies.get('hidden-goldplus-banner') === undefined,
  isGoldPlusActive: false,
  isLoadingActiveStatus: false,
  statisticSummaries: [],
  roomDetail: null,

  // Payment
  pendingBillingList: {},
  doneBillingList: {},
  billingSummary: {},
  billingContractDetail: {},
  billingInvoiceData: {},
  billingInvoicePayment: {},
  billingAlertPending: {},

  // Overall statistic data
  statisticRoomId: null,
  visitStatistics: {},
  uniqueVisitStatistics: {},
  chatStatistics: {},
  favoriteStatistics: {},

  // Statistic filter list
  visitStatisticFilters: {},
  uniqueVisitStatisticFilters: {},
  chatStatisticFilters: {},
  favoriteStatisticFilters: {},

  // Selected statistic filter
  selectedVisitStatisticFilter: {},
  selectedUniqueVisitStatisticFilter: {},
  selectedFavoriteStatisticFilter: {},
  selectedChatStatisticFilter: {},

  axisValuesPlaceholder: {
    x: [1, 2, 3, 4, 5],
    y: [null, null, null, null, null],
  },

  // Submissions
  selectedPackage: null,
  selectedRoomsForSubmission: [],
  isSubmissionFlowStartedFromEntryPoint: false,
  isFetchingSubmissionStatus: false,
  submissionStatus: null,

  // GP Submission Owner status
  isGPSubmissionStatusFetched: false,
  goldplusProperties: {},
  isGpStatusLoading: false,
  isGpStatusFetched: false,
});

const statisticGetters = {
  statisticFilters: state => statisticType =>
    state[`${statisticType.value}StatisticFilters`],
  selectedStatisticFilter: state => statisticType => {
    const selectedFilter =
      state[`selected${statisticType.pascalValue}StatisticFilter`];

    return Object.keys(selectedFilter).length
      ? selectedFilter
      : {
          key: 'yesterday',
          value: 'Hari ke-1',
          periode_description: '',
        };
  },
  statisticTitle: state => statisticType => {
    const statistics = state[`${statisticType.value}Statistics`];
    const defaultTitle = {
      visit: 'Kunjungan Iklan',
      uniqueVisit: 'Pengunjung Iklan',
      chat: 'Chat Masuk',
      favorite: 'Peminat Kos',
    };

    return statistics.statistic
      ? statistics.statistic[statisticType.key].label
      : defaultTitle[statisticType.value];
  },
  statisticLatestValue: state => statisticType => {
    const statistics = state[`${statisticType.value}Statistics`];

    return statistics.statistic
      ? statistics.statistic[statisticType.key].value
      : 0;
  },
  statisticDescription: () => statisticType => {
    const description = {
      visit:
        'Kunjungan Iklan adalah berapa kali iklan kos Anda dilihat oleh para pencari kos.',
      uniqueVisit:
        'Pengunjung Iklan adalah pencari kos yang melihat iklan Anda.',
      chat: 'Chat Masuk adalah jumlah chat yang Anda terima dari pencari kos.',
      favorite:
        'Peminat kos adalah pencari kos yang klik Favorit ❤ di iklan kos Anda.',
    };

    return description[statisticType.value];
  },
  statisticValues: state => (statisticType, axis) => {
    const statistics = state[`${statisticType.value}Statistics`];

    if (statistics.chart) {
      return statistics.chart.map(item => item[axis]);
    }

    return state.axisValuesPlaceholder[axis];
  },
  statisticGrowthStatus: state => statisticType => {
    const statistics = state[`${statisticType.value}Statistics`];

    return statistics.chart_label
      ? statistics.chart_label
      : {
          growth_type: null,
          tooltip: '',
        };
  },
  statisticFilter: (state, getters) => statisticType => {
    const statistics = state[`${statisticType.value}Statistics`];

    return statistics.filter_list
      ? statistics.filter_list[Object.keys(statistics.filter_list)[0]]
      : getters.selectedStatisticFilter(statisticType);
  },
  statisticData: (state, getters) => statisticType => ({
    title: getters.statisticTitle(statisticType),
    latestValue: getters.statisticLatestValue(statisticType),
    description: getters.statisticDescription(statisticType),
    labels: getters.statisticValues(statisticType, 'x'),
    data: getters.statisticValues(statisticType, 'y'),
    pointRadius: getters.customLastXAxisValueStyling(
      getters.statisticValues(statisticType, 'x'),
      6
    ),
    xAxisLineWidth: getters.customLastXAxisValueStyling(
      getters.statisticValues(statisticType, 'x'),
      3
    ),
    selectedFilter: getters.statisticFilter(statisticType),
    growthStatus: getters.statisticGrowthStatus(statisticType),
    isEmptyData:
      !Object.keys(state[`${statisticType.value}Statistics`]).length ||
      getters
        .statisticValues(statisticType, 'y')
        .every(element => element === null),
  }),
};

const chartGetters = {
  customLastXAxisValueStyling: () => (xAxisValues, value) =>
    xAxisValues.map((item, index) =>
      index === xAxisValues.length - 1 ? value : 0
    ),
};

export const getters = {
  ...statisticGetters,
  ...chartGetters,
};

export const mutations = {
  closeBanner(state) {
    // Set the cookie within a day
    Cookies.set('hidden-goldplus-banner', '1', {
      expires: 1,
      domain: 'mamikos.com',
    });

    state.isBannerDisplayed = false;
  },
  setIsGoldPlusActive(state, isActive = false) {
    state.isGoldPlusActive = isActive;
  },
  setIsLoadingActiveStatus(state, isLoading = false) {
    state.isLoadingActiveStatus = isLoading;
  },
  setWidgetStatisticDate(state, date = null) {
    state.widgetStatisticDate = date;
  },
  setStatisticSummaries(state, summaryData = []) {
    state.statisticSummaries = summaryData;
  },
  setRoomDetail(state, detail = null) {
    state.roomDetail = detail;
  },

  // Payment
  setBillingListpending(state, billingData = {}) {
    state.pendingBillingList = billingData;
  },
  setBillingListdone(state, billingData = {}) {
    state.doneBillingList = billingData;
  },
  setBillingSummary(state, summaryData = {}) {
    state.billingSummary = summaryData;
  },
  setBillingContractDetail(state, contractData = {}) {
    state.billingContractDetail = contractData;
  },
  setBillingInvoiceData(state, invoiceData = {}) {
    state.billingInvoiceData = invoiceData;
  },
  setBillingInvoicePayment(state, invoice = {}) {
    state.billingInvoicePayment = invoice;
  },
  setBillingAlertPending(state, pending = {}) {
    state.billingAlertPending = pending;
  },

  // Overall statistic data
  setStatisticRoomId(state, data = null) {
    state.statisticRoomId = data;
  },
  setVisitStatistics(state, data = {}) {
    state.visitStatistics = data;
  },
  setUniqueVisitStatistics(state, data = {}) {
    state.uniqueVisitStatistics = data;
  },
  setChatStatistics(state, data = {}) {
    state.chatStatistics = data;
  },
  setFavoriteStatistics(state, data = {}) {
    state.favoriteStatistics = data;
  },

  // Statistic filter list
  setVisitStatisticFilters(state, data = {}) {
    state.visitStatisticFilters = data;
  },
  setUniqueVisitStatisticFilters(state, data = {}) {
    state.uniqueVisitStatisticFilters = data;
  },
  setChatStatisticFilters(state, data = {}) {
    state.chatStatisticFilters = data;
  },
  setFavoriteStatisticFilters(state, data = {}) {
    state.favoriteStatisticFilters = data;
  },

  // Selected statistic filter
  setSelectedVisitStatisticFilter(state, data = {}) {
    state.selectedVisitStatisticFilter = data;
  },
  setSelectedUniqueVisitStatisticFilter(state, data = {}) {
    state.selectedUniqueVisitStatisticFilter = data;
  },
  setSelectedChatStatisticFilter(state, data = {}) {
    state.selectedChatStatisticFilter = data;
  },
  setSelectedFavoriteStatisticFilter(state, data = {}) {
    state.selectedFavoriteStatisticFilter = data;
  },

  // Submissions
  setSelectedPackage(state, data = null) {
    state.selectedPackage = data;
  },
  setSelectedRoomsForSubmission(state, data = []) {
    state.selectedRoomsForSubmission = data;
  },
  setIsSubmissionFlowStartedFromEntryPoint(state, isFromEntryPoint = false) {
    state.isSubmissionFlowStartedFromEntryPoint = isFromEntryPoint;
  },
  setFetchingSubmissionStatus(state, payload) {
    state.isFetchingSubmissionStatus = payload;
  },
  setGoldPlusSubmissionStatus(state, gpSubmissionStatus) {
    state.submissionStatus = gpSubmissionStatus;
  },

  // GP Submission Owner status
  setIsGPSubmissionStatusFetched(state, isFetch = false) {
    state.isGPSubmissionStatusFetched = isFetch;
  },
  setGoldplusProperties(state, payload) {
    state.goldplusProperties = payload;
  },
  setGoldplusLoading(state, payload) {
    state.isGpStatusLoading = payload;
  },
  setGoldplusStatusFetched(state, payload) {
    state.isGpStatusFetched = payload;
  },
};

export const actions = {
  async getActiveStatus({ commit }) {
    commit('setIsLoadingActiveStatus', true);

    try {
      const response = await this.$api.getOwnerGoldPlusStatus();

      if (response.data.status) {
        const isActive = Boolean(response.data.submissions.length);

        commit('setIsGoldPlusActive', isActive);
      } else {
        this.$error.report(JSON.stringify(response));
        this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
      }

      commit('setIsLoadingActiveStatus', false);
    } catch (error) {
      commit('setIsLoadingActiveStatus', false);
      this.$error.report(error);
      this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
    }
  },
  async fetchWidgetData({ commit }) {
    const response = await this.$api.getGoldPlusWidgetData();

    if (response.data.status) {
      const statistic = response.data.data;
      commit('setStatisticSummaries', statistic);
    }
  },
  async fetchWidgetBillingSummary({ commit }) {
    const response = await this.$api.getGoldPlusBillingSummary();
    if (response.data.status) {
      const summary = response.data.data;
      commit('setBillingSummary', summary);
    }
  },
  async fetchBillingList({ commit }, payload) {
    const response = await this.$api.getGoldPlusBillingList(
      payload.type,
      payload.perPage,
      payload.page
    );

    if (response) {
      const { data } = response;
      commit(`setBillingList${payload.type}`, data);
    }
  },
  async fetchBillingInvoice({ commit, dispatch }, payload) {
    let response;
    if (typeof payload !== 'object') {
      response = await this.$api.getBillingInvoice(payload);
    } else {
      response =
        payload.payload !== '0'
          ? await this.$api.getBillingInvoice(payload.payload)
          : {
              data: {
                data: payload.gpinvoice,
                contractpayload: payload.gpcontract,
              },
            };
    }

    if (response.data.data) {
      const {
        order_detail_url,
        invoice_number,
        status,
        amount,
        signature,
        nonbilling = null,
      } = response.data.data;
      const billingId = nonbilling || order_detail_url.split('/').slice(-1);

      commit('setBillingInvoicePayment', {
        order_detail_url,
        invoice_number,
        status,
        amount,
        signature,
      });

      if (nonbilling) {
        await commit('setBillingContractDetail', response.data.contractpayload);
      } else {
        await dispatch('fetchBillingContractDetail', billingId);
      }
    }
  },
  async fetchBillingAlertPending({ commit }) {
    const response = await this.$api.getBillingAlertPending();

    if (response.data.data) {
      const pending = response.data.data;
      commit('setBillingAlertPending', pending);
    }
  },
  async fetchBillingContractDetail({ commit }, payload) {
    const response = await this.$api.getBillingContractDetail(payload);

    if (response.data.data) {
      const contract = response.data.data;
      commit('setBillingContractDetail', contract);
    }
  },
  async getStatistics({ commit }, payload) {
    const response = await this.$api.getGoldPlusStatistics(
      payload.id,
      payload.type.key,
      payload.params
    );

    if (response.data.status)
      commit(`set${payload.type.pascalValue}Statistics`, response.data.data);
  },
  async getStatisticFilters({ commit }, payload) {
    const response = await this.$api.getGoldPlusStatisticFilters(
      payload.type.key,
      payload.params
    );

    if (response.data.status)
      commit(
        `set${payload.type.pascalValue}StatisticFilters`,
        response.data.data
      );
  },
  async getGoldPlusSubmissionStatus({ commit }) {
    commit('setGoldplusLoading', true);
    try {
      const response = await this.$api.getGoldPlusSubmissionStatus();
      if (response.data.status) {
        commit('setGoldplusProperties', response.data.data);
        commit('setGoldplusStatusFetched', true);
      }
      commit('setGoldplusLoading', false);
    } catch (error) {
      this.$error.report(error);
      commit('setGoldplusLoading', false);
      this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
    }
  },
  resetStatisticFilters({ commit }) {
    commit('setVisitStatisticFilters', {});
    commit('setUniqueVisitStatisticFilters', {});
    commit('setChatStatisticFilters', {});
    commit('setFavoriteStatisticFilters', {});

    commit('setSelectedVisitStatisticFilter', {});
    commit('setSelectedUniqueVisitStatisticFilter', {});
    commit('setSelectedChatStatisticFilter', {});
    commit('setSelectedFavoriteStatisticFilter', {});
  },
};
