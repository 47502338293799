import {
  getAbTestVariant,
  getStoredAbTestVariant,
  splitterApi,
} from '~/utils/abTest';

export default ({ store }, inject) => {
  inject('abTest', {
    getVariant: args => getAbTestVariant(args, store),
    getStoredVariant: args => getStoredAbTestVariant(args, store),
    splitterApi: args => splitterApi(args),
  });
};
