import _get from 'lodash/get';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import bookingRuleKeys from '../data/bookingRuleKeys';

export const getRuleSelectedState = ({ data, key }) => {
  const selectedData = _find(data, { key });
  return !!_get(selectedData, 'selected');
};

export const formatCriteriaRules = rules =>
  (rules || []).map(rule => {
    rule.useSwitch = rule.key !== 'capacity';

    if (rule.values && rule.values.length === 1) {
      rule.selected = !!rule.values[0].selected;
      rule.isMultiple = false;
      rule.selectedValue = rule.selected ? rule.values[0].tag_id : null;
    } else if (rule.values && rule.values.length > 1) {
      const selectedValue = _find(rule.values, { selected: true });
      rule.selected = !rule.useSwitch || !!selectedValue;
      rule.selectedValue = selectedValue
        ? selectedValue.tag_id
        : rule.values[0].tag_id;
      rule.isMultiple = true;
    }

    return rule;
  });

export const syncUpSettingData = data => {
  const isAllowMarriedCouples = getRuleSelectedState({
    data: data.booking_criteria_rules,
    key: bookingRuleKeys.ALLOW_MARRIED_COUPLES,
  });
  const isAllowBringChildren = getRuleSelectedState({
    data: data.booking_criteria_rules,
    key: bookingRuleKeys.ALLOW_BRING_CHILDREN,
  });
  const isRequiredFamilyCard = getRuleSelectedState({
    data: data.booking_document_rules,
    key: bookingRuleKeys.REQUIRED_FAMILY_CARD,
  });
  const isRequiredMarriageBook = getRuleSelectedState({
    data: data.booking_document_rules,
    key: bookingRuleKeys.REQUIRED_MARRIAGE_BOOK,
  });

  const setCriteriaRule = key => {
    const criteriaIndex = _findIndex(data.booking_criteria_rules, {
      key,
    });

    if (criteriaIndex >= 0) {
      const { values } = data.booking_criteria_rules[criteriaIndex];

      if (values && values.length) {
        data.booking_criteria_rules[criteriaIndex].selected = true;
        data.booking_criteria_rules[criteriaIndex].selectedValue =
          values[0].tag_id;
      }
    }
  };

  if (isRequiredFamilyCard && !isAllowBringChildren) {
    setCriteriaRule(bookingRuleKeys.ALLOW_BRING_CHILDREN);
  }

  if (isRequiredMarriageBook && !isAllowMarriedCouples) {
    setCriteriaRule(bookingRuleKeys.ALLOW_MARRIED_COUPLES);
  }

  return data;
};
