import authentication from './endpoints/authentication';
import profile from './endpoints/profile';
import finance from './endpoints/finance';
import property from './endpoints/property';
import premium from './endpoints/premium';
import tenant from './endpoints/tenant';
import mamipoin from './endpoints/mamipoin';
import mamipay from './endpoints/mamipay';
import bbk from './endpoints/bbk';
import goldplus from './endpoints/goldplus';
import abtest from './endpoints/abtest';
import contract from './endpoints/contract';
import media from './endpoints/media';
import buildService from './service';
import survey from './endpoints/survey';
import statistic from './endpoints/statistic';
import booking from './endpoints/booking';
import mamifoto from './endpoints/mamifoto';
import mamitour from './endpoints/mamitour';
import paidProductRecommendation from './endpoints/paid-product-recommendation';
import prime from './endpoints/prime';

const apiInterfaces = {
  ...authentication,
  ...profile,
  ...finance,
  ...tenant,
  ...property,
  ...mamipoin,
  ...mamipay,
  ...premium,
  ...mamipay,
  ...bbk,
  ...goldplus,
  ...abtest,
  ...survey,
  ...statistic,
  ...contract,
  ...media,
  ...booking,
  ...mamifoto,
  ...mamitour,
  ...paidProductRecommendation,
  ...prime,
};

export default (axios, vue) => buildService(axios, vue)(apiInterfaces);
