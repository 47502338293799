export const state = () => ({
  isActive: false,
  message: '',
  title: '',
  type: '',
});

export const mutations = {
  show(state, { type, title, message }) {
    state.isActive = true;
    state.type = type;
    state.title = title;
    state.message = message;
  },
  close(state) {
    state.isActive = false;
  },
};
