export default () => {
  const issueTokenURL = `${process.env.MAMIKOS_URL}/ownerpage/issue-token?redirect_url=${window.location.href}`;
  const logoutUrl = `${process.env.MAMIKOS_URL}/auth/logout`;

  if (localStorage) {
    const maxAttemptIssueTokenRequest = 3;
    const issueTokenRequestAttempt = parseInt(
      localStorage.getItem('issue-token-attempt') || 0
    );

    if (issueTokenRequestAttempt < maxAttemptIssueTokenRequest) {
      localStorage.setItem('issue-token-attempt', issueTokenRequestAttempt + 1);
      window.location.href = issueTokenURL;
    } else {
      localStorage.removeItem('issue-token-attempt');
      window.location.href = logoutUrl;
    }
  } else {
    window.location.href = issueTokenURL;
  }
};
