const today = new Date();

const monthNames = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const getCurrentMonth = (type = 'number') =>
  type === 'text'
    ? monthNames[today.getMonth()]
    : String(today.getMonth() + 1).padStart(2, '0');

export const getCurrentYear = () => today.getFullYear();

export const getMonthsFromNowTo = yearCount => {
  const TOTAL_MONTHS = 12;
  const monthCount = TOTAL_MONTHS * yearCount;
  const months = [];

  const generateMonth = i =>
    monthNames[(today.getMonth() + monthCount - i) % TOTAL_MONTHS];
  const generateYear = i =>
    today.getFullYear() -
    Math.abs(Math.floor((today.getMonth() - i) / TOTAL_MONTHS));

  for (let i = 0; i < monthCount; i++) {
    months.push(`${generateMonth(i)} ${generateYear(i)}`);
  }

  return months;
};

export const getCurrentUTCTime = () => {
  const today = new Date();
  const date = String(today.getUTCDate()).padStart(2, '0');
  const month = String(today.getUTCMonth() + 1).padStart(2, '0');
  const year = today.getUTCFullYear();
  const hours = String(today.getUTCHours()).padStart(2, '0');
  const minutes = String(today.getUTCMinutes()).padStart(2, '0');
  const seconds = String(today.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};
