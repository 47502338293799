export default async function({ store, redirect }) {
  if (!store.state.profile.data.user) {
    await store.dispatch('profile/getLatestProfile');
  }

  const isNewOwner = store.getters['profile/isNewOwner'];
  const isBbk = store.getters['profile/isBbk'];

  if (isNewOwner || !isBbk) redirect('/');
}
