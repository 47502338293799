export const state = () => ({
  financeReport: {
    total: 0,
    monthly: {
      loading: false,
      total: 0,
      month: null,
      year: null,
    },
  },
});

export const getters = {
  financeReport(state) {
    return state.financeReport;
  },
};

export const mutations = {
  updateFinanceReportTotal(state, total) {
    state.financeReport.total = total;
  },
  updateFinanceReportMontly(state, payload) {
    state.financeReport.monthly = payload;
  },
  setFinanceReportMonthlyLoading(state, isLoading) {
    state.financeReport.monthly.loading = isLoading;
  },
};

export const actions = {
  getFinanceReport({ commit, state }, params) {
    // Set loading for monthly report only
    params && commit('setFinanceReportMonthlyLoading', true);
    this.$api.getFinanceReport(params).then(res => {
      if (!params) {
        commit('updateFinanceReportTotal', res.data.total_amount);
        return;
      }
      commit('updateFinanceReportMontly', {
        ...state.financeReport.monthly,
        total: res.data.total_amount,
      });
      commit('setFinanceReportMonthlyLoading', false);
    });
  },
};
