export const state = () => ({
  show: false,
  duration: 3,
  message: '',
  position: '',
  variant: '',
  customStyle: {},
});

export const mutations = {
  setShow: (state, isShow) => {
    state.show = !!isShow;
  },
  setDuration: (state, duration) => {
    state.duration = duration;
  },
  setMessage: (state, message = '') => {
    state.message = message;
  },
  setPosition: (state, position = '') => {
    state.position = position;
  },
  setVariant: (state, variant = '') => {
    state.variant = variant;
  },
  setCustomStyle: (state, customStyle = {}) => {
    state.customStyle = customStyle;
  },
};

export const actions = {
  show: (
    { commit },
    {
      message = '',
      duration = 3,
      position = '',
      variant = '',
      customStyle = {},
    }
  ) => {
    commit('setDuration', duration);
    commit('setMessage', message);
    commit('setPosition', position);
    commit('setVariant', variant);
    commit('setCustomStyle', customStyle);
    commit('setShow', true);
  },
  close: ({ commit }) => {
    commit('setShow', false);
    commit('setDuration', 3);
    commit('setMessage', '');
    commit('setPosition', '');
    commit('setVariant', '');
    commit('setCustomStyle', {});
  },
};
