export default {
  getKosList({ params = {}, cancelToken = null } = {}) {
    return this.get('/oauth/mamipay/request/room/list', {
      params,
      cancelToken,
    });
  },
  getKosListWithContractRequest(params) {
    return this.get('/oauth/owner/booking/room/requests', { params });
  },
  getTenantDashboardData() {
    return this.get('/oauth/owner/tenant/dashboard');
  },
  sendFormSetup(setting) {
    return this.post('/oauth/owner/tenant/link', setting);
  },
  getContractRequestList(params) {
    return this.get('/oauth/owner/contract-submission', { params });
  },
  getContractRequestDetail(id) {
    return this.get(`/oauth/owner/contract-submission/${id}`);
  },
  getRoomAllotment(kosId, params = {}) {
    return this.get(
      `/oauth/mamipay/request/owner/booking/room-allotment/${kosId}`,
      { params }
    );
  },
  acceptContractRequest(contractId, params = {}) {
    return this.post(
      `/oauth/owner/contract-submission/confirm/${contractId}`,
      params
    );
  },
  rejectContractRequest(contractId) {
    return this.post(`/oauth/owner/contract-submission/reject/${contractId}`);
  },
  submitMamipayDummyAccount() {
    return this.post(`/oauth/owner/dummy-mamipay-profile`);
  },

  // Terminate contract request confirmation
  confirmTerminateContractRequest(requestId) {
    return this.get(`/oauth/mamipay/request/contract/terminate/${requestId}`);
  },
  rejectTerminateContractRequest(requestId) {
    return this.post(
      `/oauth/mamipay/request/contract/reject-terminate/${requestId}`
    );
  },

  // Update nearest contract
  updateContractNearestInvoice(contractId, payload) {
    return this.put(
      `/oauth/mamipay/request/contract/update-nearest-invoice/${contractId}`,
      payload
    );
  },

  // Terminate contract
  getTerminateContractReasons() {
    return this.get(`/oauth/mamipay/request/contract/terminate-reason/owner`);
  },
  postContractTermination(payload) {
    return this.post(
      `/oauth/mamipay/request/contract/request/terminate`,
      payload
    );
  },

  // Send contract unique code
  sendContractUniqueCodeToTenant(contractId) {
    return this.post(`/oauth/mamipay/request/contract/${contractId}/send-code`);
  },
  generateContractUniqueCode(contractId) {
    return this.post(
      `/oauth/mamipay/request/contract/${contractId}/generate-code`
    );
  },

  // Extend contract
  postAcceptExtendContractRequest(extendRequestId) {
    return this.post(
      `/oauth/mamipay/request/contract/${extendRequestId}/req-extend/accept`
    );
  },
  postRejectExtendContractRequest(extendRequestId, payload) {
    return this.post(
      `/oauth/mamipay/request/contract/${extendRequestId}/req-extend/reject`,
      payload
    );
  },
};
