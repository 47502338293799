const toast = ({ store }) => ({
  show: ({ message, duration, position, variant, customStyle }) => {
    store.dispatch('toast/show', {
      message,
      duration,
      position,
      variant,
      customStyle,
    });
  },
  close: () => {
    store.dispatch('toast/close');
  },
});

export default (ctx, inject) => {
  inject('toast', toast(ctx));
};
