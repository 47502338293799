export const state = () => ({
  surveyPreviousPath: '',
});

export const mutations = {
  setSurveyPreviousPath(state, payload) {
    state.surveyPreviousPath = '';
    state.surveyPreviousPath = payload;
  },
};
