export const state = () => ({
  selectedListingData: null,
  selectedPeriodData: null,
  emptyStatePeriod: null,
});

export const mutations = {
  setSelectedListingData(state, data = null) {
    state.selectedListingData = data;
  },
  setSelectedPeriodData(state, data = null) {
    state.selectedPeriodData = data;
  },
  setEmptyStatePeriod(state, data = null) {
    state.emptyStatePeriod = data;
  },
};
