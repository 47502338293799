import Cookies from 'js-cookie';

const options = {
  samesite: 'strict',
  domain: process.env.OAUTH2_DOMAIN,
  'max-age': '604800',
};

export function setToken(token) {
  Cookies.set('access_token', token.access_token, options);
  Cookies.set('refresh_token', token.refresh_token, options);
}

export function getAccessToken() {
  return Cookies.get('access_token');
}

export function getRefreshToken() {
  return Cookies.get('refresh_token');
}

export function clearToken() {
  Cookies.remove('access_token', options);
  Cookies.remove('refresh_token', options);
}
