export const state = () => ({
  hideSidebar: false,
  hideHeader: false,
  hideNotifChat: false,
  bodyBackground: '#fff',
  wrapperBodyNoPadding: false,
});

export const mutations = {
  setHideSidebar(state, isHide) {
    state.hideSidebar = isHide;
  },
  setHideHeader(state, isHide) {
    state.hideHeader = isHide;
  },
  setHideNotifChat(state, isHide) {
    state.hideNotifChat = isHide;
  },
  setBodyBackground(state, background) {
    state.bodyBackground = background;
  },
  setWrapperBodyNoPadding(state, isNoPadding) {
    state.wrapperBodyNoPadding = isNoPadding;
  },
};
