import { cloneDeep } from 'lodash';

const initialStates = {
  currentStep: 0,
  hideFooter: false,
  steps: {
    'step-one': {
      key: 'information',
      name: 'Data Kos',
      pageName: 'step-one',
      number: 1,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur informasi deskripsi kos dan tipe kamar',
    },
    'step-two': {
      key: 'address',
      name: 'Alamat Kos',
      pageName: 'step-two',
      number: 2,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur lokasi dan detail alamat kos',
    },
    'step-three': {
      key: 'building_photo',
      name: 'Foto Kos',
      pageName: 'step-three',
      number: 3,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur foto kos dan lingkungannya',
    },
    'step-four': {
      key: 'room_photo',
      name: 'Foto Kamar',
      pageName: 'step-four',
      number: 4,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur foto kamar dan isinya',
    },
    'step-five': {
      key: 'facility',
      name: 'Fasilitas',
      pageName: 'step-five',
      number: 5,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur fasilitas kos dan isinya',
    },
    'step-six': {
      key: 'room_allotment',
      name: 'Ketersediaan Kamar',
      pageName: 'step-six',
      number: 6,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur jumlah kamar tersedia dan terisi',
    },
    'step-seven': {
      key: 'price',
      name: 'Harga',
      pageName: 'step-seven',
      number: 7,
      complete: false,
      disabled: false,
      description: 'Untuk mengatur harga kamar dan biaya lainnya',
    },
  },
  params: {
    step1: {
      propertyId: null,
      type: null,
      name: '',
      description: '',
      rulesKost: [],
      ruleKostFiles: [],
      yearBuilding: 0,
      nameOwner: '',
      phoneOwner: '',
      addDataOwner: false,
      hasTypeName: false,
      typeName: '',
      remark: '',
    },
    step2: {
      address: '',
      coordinates: [0, 0],
      address_description: '',
      province: '',
      city: '',
      subdistrict: '',
    },
    step3: {
      firstPhoto: [],
      secondPhoto: [],
      thirdPhoto: [],
      fourthPhoto: [],
    },
    step4: {
      firstPhoto: [],
      secondPhoto: [],
      thirdPhoto: [],
      fourthPhoto: [],
    },
    step5: {
      generalFacilities: [],
      roomFacilities: [],
      restroomFacilities: [],
      parkingFacilities: [],
      nearFacilities: [],
    },
    step6: {
      sizeRoom: '',
      sizeOtherRoom: '',
      totalRoom: 0,
      roomAvailable: 0,
      roomAllotment: [],
    },
    step7: {
      priceMonth: 0,
      durationRent: 0,
      rentPriceDaily: 0,
      rentPriceWeekly: 0,
      rentPriceQuarterly: 0,
      rentPriceSemiannually: 0,
      rentPriceAnnually: 0,
      additionalCost: [],
      depositoPrice: 0,
      downPayment: 0,
      lateChargePrice: 0,
      lateChargeDuration: 0,
      lateChargeDurationType: 'day',
      priceRemark: null,
    },
  },
  isNextButtonDisabled: true,
  isActivePhotoBooking: false,
  property: {}, // For tracker purpose
};

export const state = () => cloneDeep(initialStates);

export const mutations = {
  updateSpecificCurrentStep(state, step) {
    state.currentStep = step;

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },
  updateParam(state, { step, param: paramsObj }) {
    const paramsArr = Object.entries(paramsObj);
    paramsArr.forEach(([key, value]) => {
      state.params[`step${step}`][key] = value;
    });
  },
  updateDisableButton(state, isDisabled) {
    state.isNextButtonDisabled = isDisabled;
  },
  setVisibilityFooter(state, value) {
    state.hideFooter = value;
  },
  resetState(state) {
    const clonedState = cloneDeep(initialStates);
    Object.keys(state).forEach(key => {
      state[key] = clonedState[key];
    });
  },
  completeStep(state, stepNumber) {
    Object.values(state.steps).find(step => {
      const isFound = step.number === stepNumber;
      if (isFound) state.steps[step.pageName].complete = true;
      return isFound;
    });
  },
  turnBackCompleteStep(state, stepNumber) {
    Object.values(state.steps).find(step => {
      const isFound = step.number === stepNumber;
      if (isFound) state.steps[step.pageName].complete = false;
      return isFound;
    });
  },
  disableStep(state, stepKeys) {
    stepKeys.forEach(key => {
      state.steps[key].disabled = true;
    });
  },
  updateCoverPhotosInsideRoom(state, value) {
    state.params.step4.secondPhoto.map(photo => {
      photo.is_cover = false;
      if (photo.id === value) {
        photo.is_cover = true;
      }
      return photo;
    });
  },
  updateActivePhotoBooking(state, value) {
    state.isActivePhotoBooking = value;
  },
  updateProperty(state, value) {
    state.property = value;
  },
};

export const actions = {
  // Next for complete or uncomplete step
  updateStep(context, { onAbort, queries, step, next = true }) {
    const prevStep = context.state.currentStep;
    this.$router.push(
      {
        query: {
          ...queries,
          step,
        },
      },
      () => {
        context.commit('updateSpecificCurrentStep', step);
        if (next) {
          context.commit('completeStep', prevStep);
        } else {
          context.commit('turnBackCompleteStep', prevStep);
        }
      },
      () => {
        typeof onAbort === 'function' && onAbort();
      }
    );
  },
};
