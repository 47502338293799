export default {
  getPointTotal() {
    return this.get('/oauth/user/point/total');
  },
  getTnc() {
    return this.get('/oauth/user/point/tnc');
  },
  getPointHistory(page = 1, limit = 10) {
    return this.get(`/oauth/user/point/history?page=${page}&limit=${limit}`);
  },
  getRewardList() {
    return this.get('/oauth/reward/list');
  },
  getRewardDetail(id = '') {
    return this.get(`/oauth/reward/detail/${id}`);
  },
  redeemReward(id = '', data = {}) {
    return this.post(`/oauth/reward/redeem/${id}`, data);
  },
  getRedeemDetail(id = '') {
    return this.get(`/oauth/user/redeem/detail/${id}`);
  },
  getRedeemList(data = {}) {
    return this.get(`/oauth/user/redeem/list`, data);
  },
  getPointScheme() {
    return this.get('/oauth/user/point/scheme?target=owner');
  },
};
