import Cookies from 'js-cookie';
import redirectLogin from '~/utils/redirectLogin';
import { clearAuthTracker } from '~/utils/authTracker';

window.Cookies = Cookies;

export default ({ app, store, route }) => {
  const isNoAuthenticationMetaExist =
    route.meta.findIndex(({ isNoAuthentication }) => isNoAuthentication) >= 0;

  if (!isNoAuthenticationMetaExist) {
    const accessToken = Cookies.get('access_token');
    // Redirect to mamikos's login page when unauthenticated
    if (!accessToken) {
      clearAuthTracker();
      redirectLogin();
      return;
    }
    store.commit('auth/authenticate');
  }
};
