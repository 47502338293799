const initialAcceptRequestState = () => ({
  currentAcceptRequestStep: 0,
  isAccepting: false,
  isFetchingData: false,
  selectedRoom: 0,
  roomList: [],
  isLoadedRoomList: false,
  roomAvailable: 0,
});

export const initialKosListData = () => ({
  loadingKosList: false,
  firstLoad: true,
  list: [],
  isAllBbk: false,
  pagination: {
    offset: 0,
    limit: 20,
    has_more: true,
    total: 0,
  },
});

export const state = () => ({
  kosListData: initialKosListData(),
  contractType: 'tenant',
  kosSelected: {},
  loadingContractData: false,
  dueDate: 0,
  isDueDateActive: false,
  isCardActive: false,
  contractCardProperties: {},
  kosLinkShareOptions: {},
  currentAcceptRequestStep: 0,
  contractRequest: {},
  contractRequestList: [],
  contractRequestDetail: {
    is_empty_room: true,
    is_revised_price: true,
  },
  accept: initialAcceptRequestState(),
  acceptStatus: false,
  acceptData: {},
  acceptFailedMessage: '',
  rejectStatus: false,
  rejectLoading: false,
  rejectData: {},
});

export const getters = {
  acceptRequestSteps({ contractRequestDetail }) {
    return [
      {
        title: 'Pilih nomor kamar',
        available: !!contractRequestDetail.is_empty_room,
        component: 'select-room',
        width: '344px',
      },
      {
        title: 'Pengajuan Perubahan Harga',
        available: !!contractRequestDetail.is_revised_price,
        component: 'custom-price',
        card: true,
        width: '360px',
      },
    ];
  },
  availableAcceptRequestSteps(_, { acceptRequestSteps }) {
    return acceptRequestSteps.filter(step => step.available);
  },
};

export const mutations = {
  updateKosList(state, list) {
    state.kosListData.list = list;
  },
  setKosList(state, list) {
    state.kosListData.list.push(...list);
  },
  updateKosListLoading(state, isLoading) {
    state.kosListData.loadingKosList = isLoading;
  },
  updateIsAllBbk(state, isAllBbk) {
    state.kosListData.isAllBbk = isAllBbk;
  },
  setKosListPagination(state, data) {
    state.kosListData.pagination = { ...state.kosListData.pagination, ...data };
  },
  updateFirstLoadState(state, status) {
    state.kosListData.firstLoad = status;
  },
  updateContractRequest(state, data) {
    state.contractRequest = data;
  },
  updateContractRequestDetail(state, detail) {
    state.contractRequestDetail = detail;
  },
  updateContractType(state, type) {
    state.contractType = type;
  },
  updateKosSelected(state, kos) {
    state.kosSelected = kos;
  },
  updateLoadingContractData(state, isLoading) {
    state.loadingContractData = isLoading;
  },
  updateDueDate(state, date) {
    state.dueDate = date;
  },
  updateDueDateState(state, isActive) {
    state.isDueDateActive = isActive;
  },
  updateCardState(state, isActive) {
    state.isCardActive = isActive;
  },
  updateContractProperties(state, properties) {
    state.contractCardProperties = properties;
  },
  updateKosLinkShareOption(state, option) {
    state.kosLinkShareOptions = option;
  },
  setSelectedRoom(state, room) {
    state.accept.selectedRoom = room;
  },
  setCurrentAcceptRequestStep(state, step) {
    state.accept.currentAcceptRequestStep = step;
  },
  setAcceptContractRequestLoading(state, isAccepting) {
    state.accept.isAccepting = isAccepting;
  },
  setAcceptContractGetLoading(state, isFetchingData) {
    state.accept.isFetchingData = isFetchingData;
  },
  setRoomList(state, roomList) {
    state.accept.roomList = [...roomList];
  },
  setRoomAvailable(state, roomAvailable) {
    state.accept.roomAvailable = roomAvailable;
  },
  setLoadedRoomList(state, isLoaded) {
    state.accept.isLoadedRoomList = isLoaded;
  },
  setAcceptState(state, payload) {
    state.accept = { ...payload };
  },

  updateAcceptStatus(state, status) {
    state.acceptStatus = status;
  },
  setAcceptContractData(state, data) {
    state.acceptData = data;
  },
  setAcceptFailedMessage(state, message) {
    state.acceptFailedMessage = message;
  },
  updateRejectStatus(state, status) {
    state.rejectStatus = status;
  },
  setRejectLoading(state, status) {
    state.rejectLoading = status;
  },
  setRejectContractData(state, data) {
    state.rejectData = data;
  },

  resetKosList(state) {
    state.kosListData = initialKosListData();
  },
  setKosListData(state, payload) {
    state.kosListData = payload;
  },
};

export const actions = {
  getKosList({ commit, state }, params) {
    commit('updateKosListLoading', true);
    return this.$api.getKosList({ params }).then(response => {
      if (response.data.status) {
        if (response.data.data.length) {
          state.kosListData.firstLoad
            ? commit('updateKosList', response.data.data)
            : commit('setKosList', response.data.data);
          commit('updateIsAllBbk', response.data.is_all_bbk);
          commit('updateFirstLoadState', false);
        }
        commit('setKosListPagination', {
          offset: state.kosListData.pagination.offset + 20,
          has_more: response.data.has_more,
          total: response.data.total,
        });
      }
      commit('updateKosListLoading', false);
    });
  },
  getTenantDashboardData({ commit }) {
    commit('updateLoadingContractData', true);

    return this.$api.getTenantDashboardData().then(response => {
      response.data.status &&
        commit('updateContractProperties', response.data.data);
      commit('updateLoadingContractData', false);
    });
  },
  getContractRequestList({ commit }, params) {
    commit('updateKosListLoading', true);
    return this.$api.getContractRequestList(params).then(response => {
      if (response.data.status) {
        commit('updateContractRequest', response.data.data);
      }
      commit('updateKosListLoading', false);
    });
  },
  getContractRequestDetail({ commit }, params) {
    commit('updateKosListLoading', true);
    return this.$api.getContractRequestDetail(params).then(response => {
      if (response.data.status) {
        commit('updateContractRequestDetail', response.data.data);
      }
      commit('updateKosListLoading', false);
    });
  },
  acceptContractRequest({ commit }, params) {
    commit('setAcceptContractRequestLoading', true);
    return this.$api
      .acceptContractRequest(params.contractId, params.requestProps)
      .then(response => {
        commit('updateAcceptStatus', response.data.status);

        if (response.data.status) {
          commit('setAcceptContractData', response.data.data);
        } else {
          commit('setAcceptFailedMessage', response.data.meta.message);
        }
        commit('setAcceptContractRequestLoading', false);
      })
      .catch(error => {
        commit('setAcceptContractRequestLoading', false);
        this.$error.report(error);
      });
  },
  rejectContractRequest({ commit }, params) {
    commit('setRejectLoading', true);
    return this.$api
      .rejectContractRequest(params)
      .then(response => {
        commit('updateRejectStatus', response.data.status);

        if (response.data.status) {
          commit('setRejectContractData', response.data.data);
        }
        commit('setRejectLoading', false);
      })
      .catch(error => {
        commit('setRejectLoading', false);
        this.$error.report(error);
      });
  },
  resetAcceptRequestState({ commit }) {
    commit('setAcceptState', initialAcceptRequestState());
  },
  resetKosList({ commit }) {
    commit('updateKosList', []);
    commit('setKosListPagination', {
      offset: 0,
      has_more: true,
    });
  },
};
