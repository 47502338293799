export default {
  getMamifotoFaq() {
    return this.get('/pms_api/v3/mamifoto/faq');
  },
  getMamifotoGuide() {
    return this.get('/pms_api/v3/mamifoto/guide');
  },
  getPropertyAvailability() {
    return this.get('/pms_api/v3/mamifoto/property/availability');
  },
  getMamifotoPackageList() {
    return this.get('/pms_api/v3/mamifoto/package');
  },
  getMamifotoHistoryList(params) {
    return this.get('/pms_api/v3/mamifoto/history', { params });
  },
  getMamifotoTotalHistory() {
    return this.get('/pms_api/v3/mamifoto/history/count');
  },
  submitBuyMamifoto(data) {
    return this.post('/pms_api/v3/mamifoto/buy', data);
  },
};
