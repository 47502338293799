import {
  callback as requestCallback,
  error as requestError,
} from './interceptors/request';
import {
  callback as responseCallback,
  error as responseError,
} from './interceptors/response';

export default function axiosBind(axios, vue) {
  // Create a custom axios instance
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  // Set baseURL
  axios.setBaseURL(process.env.API_URL);
  // Intercept request in order to add access_token for each api calls
  axios.interceptors.request.use(requestCallback, requestError);
  /**
   * Intercept response in order to request new access_token when it is expired
   * bind `error` function with vue instance
   */
  window.responseInterceptor = axios.interceptors.response.use(
    responseCallback,
    responseError.bind(vue)
  );

  // Bind all interfaces with axios
  return apiInterfaces => {
    Object.keys(apiInterfaces).forEach(api => {
      apiInterfaces[api] = apiInterfaces[api].bind(axios);
    });
    return apiInterfaces;
  };
}
