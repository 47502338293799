import Vue from 'vue';

/**
 * For good documentation, please update this!
 *
 * Popup Order by Page:
 * - Dashboard page: All initial modals, Update Property Modal (1), Daily Allocation Modal (2), Mamipoin Widget popover (101), Premium Widget popover (102)
 * - Rooms Edit page: All initial modals, onboarding (100)
 */

/**
 * How to use:
 * - Put v-popup-order directive in component that has v-if. Don't use v-show
 * - It will queue all popups by hiding and showing in order
 */

/**
 * [
 *  {
 *   name: popup name
 *   el: Array of node or single node
 *   order: the sequence of popup
 *   onVisible: callback function that will be called when popup visible
 *  }
 * ]
 */
const popups = [];
let order = 0;
const nextPopup = () => {
  if (popups.length) {
    let currentPopup;
    let currentIdx;

    // Find next order
    order = 0;
    popups.forEach((popup, index) => {
      if (popup.order < order) {
        // eslint-disable-next-line prefer-destructuring
        order = popup.order;
        currentIdx = index;
        currentPopup = popup;
      } else if (!order) {
        // eslint-disable-next-line prefer-destructuring
        order = popup.order;
        currentIdx = index;
        currentPopup = popup;
      }
    });

    // Remove current popup from popups
    popups.splice(currentIdx, 1);

    if (!currentPopup) return;

    // Remove css property
    if (Array.isArray(currentPopup.el)) {
      // Remove all css property in all elements
      currentPopup.el.forEach(el => {
        el.style.removeProperty('display');
      });
    } else {
      currentPopup.el.style.removeProperty('display');
    }
    // Fire callback
    currentPopup.onVisible && currentPopup.onVisible();
  }
};

const addToElements = (el, name, order = 0, cb = null) => {
  // When element already exists, append new element
  const idx = popups.findIndex(popup => popup.name === name);
  if (idx >= 0) {
    const firstEl = popups[idx].el;
    popups[idx].el = Array.isArray(firstEl) ? [...firstEl, el] : [firstEl, el];
  } else {
    popups.push({ name, el, order, onVisible: cb });
  }
};

const destroy = () => {
  popups.splice(0, popups.length);
};

Vue.directive('popup-order', {
  /**
   * Value can be:
   * - string
   * - object: name, order, onVisible
   *
   */
  bind(el, { value }) {
    if (typeof value === 'string') {
      addToElements(el, value);
    } else if (typeof value === 'object') {
      addToElements(el, value.name, value.order, value.onVisible);
    }
  },
  inserted(el) {
    el.style.display = 'none';
  },
});

export default (ctx, inject) => {
  inject('popupOrder', {
    destroy,
    next: nextPopup,
  });
};
