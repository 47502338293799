import _get from 'lodash/get';
import axios from 'axios';
import { getDeviceId, getSessionId } from '~/utils/clientData';

export const splitterApi = async ({ userId, experimentId, defaultVariant }) => {
  const apiSplitterUrl = process.env.SPLITTER_URL;
  const apiAuthorization = process.env.AB_TEST_AUTHORIZATION;
  const apiKey = process.env.AB_TEST_API_KEY;

  try {
    const res = await axios({
      method: 'POST',
      url: apiSplitterUrl,
      data: {
        user_id: userId,
        experiment_id: experimentId,
        device_id: getDeviceId(),
        session_id: getSessionId(),
      },
      timeout: 5000,
      transformRequest: [
        function(data, headers) {
          headers.Authorization = apiAuthorization;
          headers['x-api-key'] = apiKey;
          headers['Content-Type'] = 'application/json';
          return JSON.stringify(data);
        },
      ],
    });

    if (res && res.data && res.data.status === 'success' && res.data.data) {
      const { is_active, experiment_id, experiment_value } = res.data.data;

      if (is_active && experiment_id === experimentId) {
        return experiment_value;
      }
    }

    return defaultVariant;
  } catch (error) {
    return defaultVariant;
  }
};

export const getStoredAbTestVariant = (
  { experimentKey, sessionName, sessionId },
  store
) => {
  // Check from vuex state first
  if (store && store.state.abTest[experimentKey]) {
    return store.state.abTest[experimentKey];
  }

  // Then check from localStorage
  if (localStorage && localStorage.getItem(sessionName)) {
    const abTestStorageData = JSON.parse(localStorage.getItem(sessionName));
    const storedSessionId = _get(abTestStorageData, 'sessionId');

    /**
     * If user still has the same sessionId, use variant from storage
     * otherwise need to get new variant data
     */
    if (abTestStorageData && storedSessionId === sessionId) {
      return _get(abTestStorageData, 'variant', '');
    }
    localStorage && localStorage.removeItem(sessionName);
  }

  return '';
};

export const getAbTestVariant = async (
  { userId, api, experimentKey, defaultVariant = 'control' },
  store
) => {
  if (!experimentKey) return '';

  const AB_TEST_SESSION_NAME = `ab_${experimentKey}`;
  const sessionId = getSessionId();

  const currentUserId =
    userId || (store ? _get(store.getters, '[profile/user].user_id', 0) : null);

  if (!currentUserId) return '';

  const storeToLocal = variant => {
    localStorage &&
      localStorage.setItem(
        AB_TEST_SESSION_NAME,
        JSON.stringify({
          variant,
          sessionId,
        })
      );
  };

  const setVariant = variant => {
    store && store.commit('abTest/setVariant', { [experimentKey]: variant });
    storeToLocal(variant);
  };

  const storedVariant = getStoredAbTestVariant({
    store,
    experimentKey,
    sessionName: AB_TEST_SESSION_NAME,
    sessionId,
  });

  if (storedVariant) {
    setVariant(storedVariant);
    return storedVariant;
  }

  try {
    const res = await api();
    const resStatus = _get(res, 'data.status', false);
    const resData = _get(res, 'data.data', null);

    if (!resStatus) {
      return defaultVariant;
    }

    const experimentData = _get(resData, experimentKey, null);
    const useVariant = _get(experimentData, 'use_varian', false);
    const experimentId = _get(experimentData, 'experiment_id', 0);

    if (useVariant) {
      const variant = _get(experimentData, 'varian', defaultVariant);
      setVariant(variant);
      return variant;
    } else if (experimentId) {
      const variant = await splitterApi({
        userId: currentUserId,
        experimentId,
        defaultVariant,
      });
      setVariant(variant);
      return variant;
    }
    setVariant(defaultVariant);
    return defaultVariant;
  } catch (error) {
    setVariant(defaultVariant);
    return defaultVariant;
  }
};
