export const state = () => ({
  hasBroadcastChatAvailable: null,
  hasBroadcastChatKos: null,
  hasAvailableRoom: null,
  selectedKos: null,
});

export const mutations = {
  setBroadcastChatAvailable(state, payload) {
    state.hasBroadcastChatAvailable = payload;
  },
  setBroadcastChatKos(state, payload) {
    state.hasBroadcastChatKos = payload;
  },
  setBroadcastChatAvailableRoom(state, payload) {
    state.hasAvailableRoom = payload;
  },
  setSelectedKos(state, payload) {
    state.selectedKos = payload;
    localStorage.setItem('broadcastChat/selectKos', JSON.stringify(payload));
  },
};

export const actions = {
  async getAvailableBroadcastChat({ commit }) {
    try {
      const response = await this.$api.getBroadcastChatAvailable();

      if (response.data.status) {
        const {
          broadcast_chat_available,
          has_broadcast_chat_kos,
          has_available_room,
        } = response.data.data;

        commit('setBroadcastChatAvailable', broadcast_chat_available);
        commit('setBroadcastChatKos', has_broadcast_chat_kos);
        commit('setBroadcastChatAvailableRoom', has_available_room);
      } else {
        this.$error.report(JSON.stringify(response));
        this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
      }
    } catch (error) {
      this.$error.report(error);
      this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
    }
  },
};
