import { AES } from 'crypto-es/lib/aes.js';
import { Utf8, WordArray, Hex } from 'crypto-es/lib/core.js';
import { CBC, Pkcs7 } from 'crypto-es/lib/cipher-core';

// eslint-disable-next-line func-style
export function encrypt(str) {
  const secretKey = 'TNIfWZS3424gmRyg';
  const randomKey = WordArray.random(8);
  const initialVector = randomKey.toString(Hex);

  const encrypted = AES.encrypt(str, Utf8.parse(secretKey), {
    iv: Utf8.parse(initialVector),
    mode: CBC,
    padding: Pkcs7,
  });

  return `${initialVector}:${encrypted.toString()}`;
}
