export default {
  getRoomStatistic() {
    return this.get('/pms_api/v3/room/statistic');
  },

  getRecommendationCards(listingId) {
    return this.get(`/pms_api/v3/owner/product-recommendation/${listingId}`);
  },

  getRoomType(params) {
    return this.get('/pms_api/v3/owner/room-type', { params });
  },

  getRoomTypeById(roomTypeId) {
    return this.get(`/pms_api/v3/owner/room-type/${roomTypeId}`);
  },

  getRoomTypeActive(params) {
    return this.get('/pms_api/v3/owner/room-type/count', { params });
  },

  getStatisticRoomById({ listingId, params }) {
    return this.get(`/pms_api/v3/room/statistic/${listingId}`, { params });
  },

  getPerformanceTooltip() {
    return this.get('/pms_api/v3/room/statistic/performance-tooltip');
  },

  getTenantIdForDeeplinkTBC() {
    return this.get('/pms_api/v3/tenant/background/deeplink');
  },
};
