import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _get from 'lodash/get';

import bookingRuleKeys from '~/components/page-features/booking-setting/data/bookingRuleKeys';
import {
  syncUpSettingData,
  formatCriteriaRules,
  getRuleSelectedState,
} from '~/components/page-features/booking-setting/utils/bookingRules';

export const state = () => ({
  selectedKosId: 0,
  kosList: [],
  kosListLimit: 20,
  kosListHasMore: true,
  isFetchingKosList: false,
  defaultKos: null,
  settingData: null,
  durationData: {
    daily: {
      min: 1,
      max: 30,
      step: 1,
      label: 'Harian',
      unit: 'hari',
    },
    weekly: {
      min: 1,
      max: 12,
      step: 1,
      label: 'Mingguan',
      unit: 'minggu',
    },
    monthly: {
      min: 1,
      max: 12,
      step: 1,
      label: 'Bulanan',
      unit: 'bulan',
    },
    quarterly: {
      min: 3,
      max: 12,
      step: 3,
      label: '3 Bulanan',
      unit: 'bulan',
    },
    semiannually: {
      min: 6,
      max: 12,
      step: 6,
      label: '6 Bulanan',
      unit: 'bulan',
    },
    yearly: {
      min: 1,
      max: 3,
      step: 1,
      label: 'Tahunan',
      unit: 'tahun',
    },
  },
});

export const getters = {
  bookingCriteriaRules({ settingData }) {
    return _get(settingData, 'booking_criteria_rules') || [];
  },
  bookingDocumentRules({ settingData }) {
    return _get(settingData, 'booking_document_rules') || [];
  },
  isAllowMarriedCouples(_, { bookingCriteriaRules }) {
    return getRuleSelectedState({
      data: bookingCriteriaRules,
      key: bookingRuleKeys.ALLOW_MARRIED_COUPLES,
    });
  },
  isAllowBringChildren(_, { bookingCriteriaRules }) {
    return getRuleSelectedState({
      data: bookingCriteriaRules,
      key: bookingRuleKeys.ALLOW_BRING_CHILDREN,
    });
  },
  isRequiredMarriageBook(_, { bookingDocumentRules }) {
    return getRuleSelectedState({
      data: bookingDocumentRules,
      key: bookingRuleKeys.REQUIRED_MARRIAGE_BOOK,
    });
  },
  isRequiredFamilyCard(_, { bookingDocumentRules }) {
    return getRuleSelectedState({
      data: bookingDocumentRules,
      key: bookingRuleKeys.REQUIRED_FAMILY_CARD,
    });
  },
  isRequiredIdCard(_, { bookingDocumentRules }) {
    return getRuleSelectedState({
      data: bookingDocumentRules,
      key: bookingRuleKeys.REQUIRED_ID_CARD,
    });
  },
  isLimitingTenantJob(_, { bookingCriteriaRules }) {
    return getRuleSelectedState({
      data: bookingCriteriaRules,
      key: bookingRuleKeys.TENANT_JOB_STATUS,
    });
  },
  maxRenter(_, { bookingCriteriaRules }) {
    const capacity = _find(bookingCriteriaRules, {
      key: bookingRuleKeys.CAPACITY,
    });

    if (capacity && capacity.selectedValue && capacity.values) {
      const selectedCapacity = _find(capacity.values, {
        tag_id: capacity.selectedValue,
      });

      if (selectedCapacity) {
        return selectedCapacity.max_renter;
      }
    }

    return 0;
  },
};

export const mutations = {
  setSelectedKosId(state, payload) {
    state.selectedKosId = payload;
  },
  setKosList(state, payload) {
    state.kosList = payload;
  },
  setKosListLoading(state, payload) {
    state.isFetchingKosList = payload;
  },
  setKosListHasMore(state, payload) {
    state.kosListHasMore = payload;
  },
  setDefaultKos(state, payload) {
    state.defaultKos = payload;
  },
  setSettingData(state, detail) {
    if (detail) {
      const formattedCriteriaRules = formatCriteriaRules(
        _get(detail, 'booking_criteria_rules') || []
      );

      state.settingData = syncUpSettingData({
        ...detail,
        booking_criteria_rules: formattedCriteriaRules,
      });
    } else {
      state.settingData = null;
    }
  },
  setRentTypeDuration({ settingData }, { unit, duration }) {
    const rentTypeIndex = _findIndex(settingData?.rent_count, {
      duration_unit: unit,
    });

    if (rentTypeIndex >= 0) {
      settingData.rent_count[rentTypeIndex].duration = duration;
    }
  },
  setCheckinSettingData(
    { settingData },
    { minAmount, maxAmount, minTimeUnit, maxTimeUnit }
  ) {
    if (settingData) {
      settingData.min_checkin_date = minAmount;
      settingData.max_checkin_date = maxAmount;
      settingData.min_checkin_date_unit = minTimeUnit;
      settingData.max_checkin_date_unit = maxTimeUnit;
    }
  },
  setSelectedDocumentRule({ settingData }, { value, key }) {
    const ruleIndex = _findIndex(settingData?.booking_document_rules, { key });

    if (ruleIndex >= 0) {
      settingData.booking_document_rules[ruleIndex].selected = !!value;
    }
  },
  setActiveCriteriaRule({ settingData }, { key, value }) {
    const ruleIndex = _findIndex(settingData?.booking_criteria_rules, { key });

    if (ruleIndex >= 0) {
      const { values } = settingData.booking_criteria_rules[ruleIndex];
      settingData.booking_criteria_rules[ruleIndex].selected = !!value;

      if (value && values && values.length) {
        settingData.booking_criteria_rules[ruleIndex].selectedValue =
          values[0].tag_id;
      } else {
        settingData.booking_criteria_rules[ruleIndex].selectedValue = null;
      }
    }
  },
  setSelectedCriteriaRuleOption({ settingData }, { key, value }) {
    const ruleIndex = _findIndex(settingData?.booking_criteria_rules, { key });

    if (ruleIndex >= 0 && value) {
      settingData.booking_criteria_rules[ruleIndex].selected = true;
      settingData.booking_criteria_rules[ruleIndex].selectedValue = value;
    }
  },
};
