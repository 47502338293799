export default {
  REQUIRED_MARRIAGE_BOOK: 'required_marriage_book',
  REQUIRED_FAMILY_CARD: 'required_family_card',
  REQUIRED_ID_CARD: 'required_identity_booking',
  ALLOW_MARRIED_COUPLES: 'is_married',
  ALLOW_BRING_CHILDREN: 'is_bring_child',
  CAPACITY: 'capacity',
  TENANT_JOB_STATUS: 'job_status',
  TENANT_JOB_STUDENT: 'student',
  TENANT_JOB_EMPLOYEE: 'employee',

  REQUIRED_ID_CARD_TRACKER: 'doc_ktp',
  REQUIRED_FAMILY_CARD_TRACKER: 'doc_kk',
  REQUIRED_MARRIAGE_BOOK_TRACKER: 'doc_bukunikah',
  TENANT_JOB_STUDENT_TRACKER: 'only_mahasiswa',
  TENANT_JOB_EMPLOYEE_TRACKER: 'only_karyawan',
};
