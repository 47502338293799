import Vue from 'vue';
import * as Sentry from '@sentry/vue';

const startVendorService = () => {
  let sentry = { captureException: () => {} };

  if (process.env.SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: process.env.SENTRY_DSN,
      beforeSend(event, hint) {
        const error = hint.originalException;
        if (
          error &&
          error.message &&
          error.message.match('TransactionInactiveError')
        ) {
          return null;
        }
        return event;
      },
    });
    sentry = {
      captureException: error => {
        Sentry.captureException(error);
      },
    };
  } else {
    console.warn('No Sentry laravel DSN FE key');
  }

  return {
    notify: error => {
      sentry.captureException(error);
    },
  };
};

const vendorInstance = startVendorService();

export const errorMonitoring = {
  report(error) {
    vendorInstance.notify(error);
  },
};

export default (ctx, inject) => {
  inject('error', errorMonitoring);
};
