import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import placehoderImage from '~/assets/images/placeholder_loading.svg';

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: placehoderImage,
  loading: placehoderImage,
  attempt: 2,
});
