import eventBus from '~/components/event-bus';

/**
 * For prevent leaving url,
 */

export default ({ app, store }, inject) => {
  app.router.beforeEach((to, from, next) => {
    // Set flag is current page is accessed as deeplink/direct access
    const previousRoutes = from.matched.length;
    const isDirectAccess = previousRoutes === 0;

    sessionStorage.setItem('isDirectAccessRoute', isDirectAccess);
    // End set direct access flag

    if (
      store.state.changes &&
      store.state.changes.showWarning &&
      store.state.changes.hasChanges
    ) {
      const { description } = store.state.changes;
      eventBus.$emit(
        'show-changes-intercept',
        answer => {
          if (answer) {
            // Restore to false when user confirm yes
            store.commit('changes/updateShowWarning', false);
            store.commit('changes/updateHasChanges', false);
            // Remove event listener also
            window.removeEventListener(
              'beforeunload',
              window.beforeunloadhandler
            );
            next();
          } else {
            next(false);
          }
        },
        description
      );
    } else {
      next();
    }
  });

  // You can add custom title or image path if you need
  const init = (customDescription = '') => {
    if (!store.state.changes) {
      store.registerModule('changes', {
        namespaced: true,
        state: {
          hasChanges: false,
          showWarning: false,
          title: '',
          description:
            customDescription ||
            'Jika keluar dari halaman ini, data anda belum tersimpan.',
          isSwitchButtonEvent: false,
          buttonTextLeft: '',
          buttonTextRight: '',
          isCustomButtonLeftEvent: false,
          isCustomButtonRightEvent: false,
        },
        mutations: {
          updateShowWarning(state, isShow) {
            state.showWarning = isShow;
          },
          updateHasChanges(state, isChange) {
            state.hasChanges = isChange;
          },
          updateTitle(state, title) {
            state.title = title;
          },
          updateDescription(state, description) {
            state.description = description;
          },
          updateSwitchButtonEvent(state, value) {
            state.isSwitchButtonEvent = value;
          },
          updateButtonName(state, { button1, button2 }) {
            state.buttonTextLeft = button1;
            state.buttonTextRight = button2;
          },
          updateCustomButtonEventLeft(state, value) {
            state.isCustomButtonLeftEvent = value;
          },
          updateCustomButtonEventRight(state, value) {
            state.isCustomButtonRightEvent = value;
          },
        },
      });
    }

    store.commit('changes/updateShowWarning', true);
  };
  const update = val => {
    store.commit('changes/updateHasChanges', val);

    /**
     * This is event when user is closing or reloading tab previous event needs
     * to be removed first to create new one so that there are multiple events
     */
    window.removeEventListener('beforeunload', window.beforeunloadhandler);
    if (val) {
      window.beforeunloadhandler = event => {
        event.returnValue = `Are you sure you want to leave?`;
      };
      window.addEventListener('beforeunload', window.beforeunloadhandler);
    }
  };
  inject('changes', { init, update });
};
