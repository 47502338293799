import _get from 'lodash/get';

const initContractListParams = () => ({
  hasMore: true,
  offset: 0,
  limit: 20,
  status: 'all',
});

const initErrorData = () => ({
  status: false,
  message: '',
});

export const state = () => ({
  tenantList: [],
  loading: false,
  params: initContractListParams(),
  page: 1,
  tenantTotal: 0,
  error: initErrorData(),
  downloadTenant: false,
  details: {},
  terminateReasons: [],
});

export const mutations = {
  setTenants(state, payload) {
    state.tenantList = payload;
  },
  resetTenants(state, list) {
    state.list = list;
  },
  setHasMore(state, payload) {
    state.params.hasMore = payload;
  },
  setOffset(state, payload) {
    state.params.offset = payload;
  },
  setLimit(state, payload) {
    state.params.limit = payload;
  },
  setStatus(state, payload) {
    state.params.status = payload;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setPage(state, payload) {
    state.page = payload;
  },
  setTenantTotal(state, payload) {
    state.tenantTotal = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setDownloadTenant(state, payload) {
    state.downloadTenant = payload;
  },
  updateTenantDetails(state, payload) {
    state.details = payload;
  },
  resetTenantContractList(state, status) {
    state.list = [];
    state.tenantTotal = 0;
    state.error = initErrorData();
    state.params = {
      ...initContractListParams(),
      status,
    };
  },
  setTerminateReasons(state, reasons) {
    state.terminateReasons = reasons || [];
  },
};

export const getters = {
  isEditableTenantData({ details }) {
    const contractStatus = _get(details, 'status_contract', '');
    return !['terminated', 'req_terminate_accepted'].includes(contractStatus);
  },
  tenantProfile({ details }) {
    return _get(details, 'contract_profile', {});
  },
  billingRule({ details }) {
    return _get(details, 'billing_rule', {});
  },
};

export const actions = {
  getTenants({ commit }, params) {
    commit('setLoading', true);

    return this.$api.getTenants(params).then(res => {
      if (res.data.status) {
        if (res.data.data) {
          commit('setTenants', res.data.data);
          commit('setHasMore', res.data.has_more);
          commit('setOffset', params.offset);
          commit('setTenantTotal', res.data.total);

          commit('setLoading', false);
          return true;
        }
      } else {
        commit('setError', {
          status: true,
          message: res.data.meta.message || 'Terjadi galat, silakan coba lagi',
        });
      }

      commit('setLoading', false);
      return false;
    });
  },
};
