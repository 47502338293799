import axios from 'axios';
import { errorMonitoring } from '~/plugins/error-monitoring';
import { $dayjs } from '~/plugins/dayjs';
import { encrypt } from '~/utils/AES-encryptor';
import { getDeviceId, getSessionId } from '~/utils/clientData';

let userData = null;

export const getLocalDateInIso8601Format = () =>
  $dayjs().format('YYYY-MM-DDTHH:mm:ssZ');

const getUserData = (userId = null) => {
  const deviceId = getDeviceId();
  const sessionId = getSessionId();

  return {
    user_id: userId,
    device_id: deviceId,
    session_id: sessionId,
    platform: window.innerWidth < 768 ? 'Web Mobile' : 'Web Desktop',
    app_version: process.env.APP_VERSION,
  };
};

// eslint-disable-next-line no-shadow
const gerTrackingData = (params, userData) => ({
  user: userData,
  e: params[0],
  event_time: getLocalDateInIso8601Format(),
  a: typeof params[1] === 'object' ? params[1] : params[2],
  URL: window.location.href,
});

const sendEventToLogger = trackingData => {
  const apiLoggerUrl = process.env.API_LOGGER_URL;

  axios({
    method: 'POST',
    url: apiLoggerUrl,
    data: {
      param: encrypt(JSON.stringify(trackingData)),
    },
    headers: {
      'x-api-key': '6QizyeWpdD3LXAs9f4CwE1zPrTY7EbNr4DVEGp82',
    },
  }).catch(error => {
    errorMonitoring.report(
      JSON.stringify({
        source: 'tracker_logger',
        error,
        event_params: trackingData,
      })
    );
  });
};

export const send = (type, params = []) => {
  // You can add more tracker in this object.
  const trackers = {
    moe: () => {
      const [eventName, eventParam] = params;
      Moengage.track_event(eventName, eventParam);
      const trackingData = gerTrackingData(params, userData);

      // Also track using Logger API for moe tracker
      sendEventToLogger(trackingData);
    },
    logger: () => {
      const trackingData = gerTrackingData(params, userData);

      sendEventToLogger(trackingData);
    },
  };

  trackers[type]();
};

export const init = (user, membership) => {
  Moengage.add_unique_user_id(parseInt(user.user_id));
  Moengage.add_email(user.email);
  Moengage.add_mobile(user.phone_number);
  Moengage.add_user_name(user.name);
  Moengage.add_user_attribute('created_at', new Date(user.created_at));
  Moengage.add_user_attribute('is_owner', true);
  Moengage.add_user_attribute('is_verify', user.is_verify);
  Moengage.add_user_attribute('kost_total', user.kost_total);
  Moengage.add_user_attribute('kost_total_active', user.kost_total_active);
  Moengage.add_user_attribute('vacancy_count', user.vacancy_count);
  Moengage.add_user_attribute('vacancy_total', user.vacancy_total);
  Moengage.add_user_attribute('status', membership.status);
  Moengage.add_user_attribute('premium_package_id', membership.package_id);

  userData = getUserData(user.user_id);

  window.tracker = send;
  window.currentChatSource = 'owner dashboard';
};

export const destroy = () => {
  Moengage.destroy_session();
};

export default (ctx, inject) => {
  inject('tracker', {
    send,
    init,
    destroy,
  });
};
