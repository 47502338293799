export default {
  getBookingList(params = {}, cancelToken = null) {
    return this.get('/oauth/mamipay/request/owner/booking', {
      params,
      cancelToken,
    });
  },
  getBookingDetail(id) {
    return this.get(`/oauth/mamipay/request/owner/booking/detail/${id}`);
  },
};
