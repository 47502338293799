/**
 * This vuex store must be used with
 * '/page-features/goldplus/component/GoldPlusUnpaidInvoiceModal' component
 * The purpose of this vuex currently is
 * for handling GPLT (Multiple Invoice) Pop up modal
 */

/* eslint-disable camelcase, no-inline-comments */
export const state = () => ({
  isShowModalUnpaidGp: false,
  isRedirectingUnpaidInvoiceToPayment: false,
  selectedPeriodeGP: null,

  isRedirectToSubmission: true,
});

export const getters = {
  isShowModalUnpaidGp(state) {
    return state.isShowModalUnpaidGp;
  },
  isRedirectingUnpaidInvoiceToPayment(state) {
    return state.isRedirectingUnpaidInvoiceToPayment;
  },
  goldplusProperties(state, _, rootState) {
    return rootState.goldplus.goldplusProperties;
  },
  hasUnpaidInvoice(state, getters) {
    return getters.goldplusProperties.gp_status?.key?.indexOf('waiting') >= 0;
  },
  hasMultipleInvoice(state, getters) {
    return getters.goldplusProperties.gp_status?.key === 'waiting_multiple';
  },
  selectedPeriodeGP(state) {
    return state.selectedPeriodeGP;
  },
  isRedirectToSubmission(state) {
    return state.isRedirectToSubmission;
  },
};

export const mutations = {
  setIsShowModalUnpaidGp(state, value) {
    state.isShowModalUnpaidGp = value;
  },
  setIsRedirectingUnpaidInvoiceToPaymentLoading(state, value) {
    state.isRedirectingUnpaidInvoiceToPayment = value;
  },
  setIsRedirectToSubmission(state, value) {
    state.isRedirectToSubmission = value;
  },
  setSelectedPeriodeGP(state, value) {
    /**
     * Set to localStorage for use in detail page when page is reloaded
     */
    state.selectedPeriodeGP = value;
    localStorage.setItem('gpSubmissionSelectedPeriode', JSON.stringify(value));
  },
};

export const actions = {
  handleClickShowUnpaidInvoice({ commit, dispatch, getters }) {
    if (getters.hasMultipleInvoice) {
      commit('setIsShowModalUnpaidGp', true);
    } else {
      dispatch('handleSingleInvoiceRedirectToPayment');
    }
  },
  async handleSingleInvoiceRedirectToPayment({ commit, getters }) {
    const invoiceId = getters.goldplusProperties?.gp_status?.invoice_id || 0;
    const MAMIPAY_BASE_URL =
      process.env.MAMIPAY_BASE_URL || 'https://pay.mamikos.com';

    commit('setIsRedirectingUnpaidInvoiceToPaymentLoading', true);

    const billingInvoice = await this.$api.getBillingInvoice(invoiceId);
    if (billingInvoice.data.status) {
      const signatureUrl = billingInvoice.data.data.signature;

      window.location.assign(
        `${MAMIPAY_BASE_URL}/invoice/select-payment/${invoiceId}?${signatureUrl}`
      );
    } else {
      this.$error.report(JSON.stringify(billingInvoice));
      this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
    }

    commit('setIsRedirectingUnpaidInvoiceToPaymentLoading', false);
  },
};
