export default {
  // GENERAL
  getOwnerGoldPlusStatus() {
    return this.get('/oauth/me/goldplus-submission');
  },
  getGoldPlusWidgetData() {
    return this.get('/oauth/room/goldplus/widget-room');
  },
  getGoldPlusRoomDetail(id) {
    return this.get(`/oauth/room/goldplus/${id}`);
  },

  // STATISTICS
  fetchGoldPlusStatisticRoomList(params) {
    return this.get(`/oauth/room/goldplus`, { params });
  },
  getGoldPlusStatisticRoomListFilters() {
    return this.get('/oauth/room/goldplus/filters');
  },
  getGoldPlusStatistics(id, type, params) {
    return this.get(`/oauth/room/goldplus/${id}/statistics/${type}`, {
      params,
    });
  },
  getGoldPlusStatisticFilters(type, params) {
    return this.get(`/oauth/room/goldplus/statistics/filters/${type}`, {
      params,
    });
  },

  // SUBMISSION/ACQUISITION
  fetchGoldPlusSubmissionOnboardingContent() {
    return this.get(`/oauth/goldplus/submissions/onboarding`);
  },
  getGpPackageOnboarding() {
    return this.get(`/oauth/goldplus/onboarding/package`);
  },
  fetchGoldPlusSubmissionRoomList(params) {
    return this.get('/oauth/room/goldplus/list-kost', { params });
  },
  getGoldPlusActiveContract() {
    return this.get('/oauth/me/goldplus/active-contract');
  },
  getGoldPlusSubmissionRoomListFilters() {
    return this.get('/oauth/room/goldplus/list-kost/filters');
  },
  // Old GP Submission API (deprecated)
  sendGoldPlusSubmissionRequest(payload) {
    return this.post(`/oauth/goldplus/submissions?v=2`, payload);
  },
  // New GP Submission API
  sendGoldPlusSubscribeRequest(payload) {
    return this.post(`/pms_api/v3/owner/goldplus/subscribe`, payload);
  },
  getGoldPlusPackages() {
    return this.get(`/oauth/goldplus/packages/`, { params: { v: '2' } });
  },
  getGoldPlusSubmissionStatus() {
    return this.get('/oauth/me/goldplus-submission/status');
  },
  getGoldplusBenefits() {
    return this.get('/oauth/goldplus/onboarding/submissions/benefits', {
      params: { v: '2' },
    });
  },
  sendGoldPlusUpgradeSubmissionRequest() {
    return this.post('/pms_api/v3/owner/goldplus/upgrade', {});
  },

  // PAYMENT
  getGoldPlusBillingSummary() {
    return this.get(`oauth/mamipay/request/owner/goldplus/billing/summary`);
  },
  getGoldPlusBillingList(type, perPage, page) {
    return this.get(
      `/pms_api/v3/owner/goldplus/history/${type}?per_page=${perPage}&page=${page}`
    );
  },
  getBillingContractDetail(id) {
    return this.get(`oauth/mamipay/request/property/contract/order/${id}`);
  },
  createBillingContractOrder(
    property_contract_id,
    premium_package_id = null,
    is_upgrade = {}
  ) {
    const balance_params = premium_package_id ? { premium_package_id } : {};
    return this.post('oauth/mamipay/request/property/contract/order', {
      property_contract_id,
      ...balance_params,
      ...is_upgrade,
    });
  },
  getBillingInvoice(invoice_id) {
    return this.get(`oauth/mamipay/request/invoice/show/${invoice_id}`);
  },
  getBillingAlertPending() {
    return this.get(
      `oauth/mamipay/request/owner/goldplus/billing/show/pending`
    );
  },

  // WIDGET UNSUBSCRIBE
  getWidgetUnsubscribeStatus() {
    return this.get(`/oauth/mamipay/request/owner/goldplus/widget/unsubscribe`);
  },

  // GOLDPLUS GUIDES
  getGoldPlusGuideLists() {
    return this.get('/oauth/goldplus/onboarding/kost-business');
  },

  // Broadcast Chat
  getBroadcastChatKosList(params) {
    return this.get('/oauth/goldplus/broadcast-chat/kos-list', { params });
  },
  getBroadcastChatAvailable() {
    return this.get('/oauth/goldplus/broadcast-chat/available');
  },
  getBroadcastChatReceiver(room_id) {
    return this.get(`/oauth/goldplus/broadcast-chat/receiver/${room_id}`);
  },
  getBroadcastChatTemplates() {
    return this.get('/oauth/goldplus/broadcast-chat/templates');
  },
  getHistoryBroadcastChatList(params) {
    return this.get('/oauth/goldplus/broadcast-chat/chat', { params });
  },
  getDetailHistoryBroadcastChatList(id) {
    return this.get(`/oauth/goldplus/broadcast-chat/chat/${id}`);
  },
  sendBroadcastChat(payload) {
    return this.post(`/oauth/goldplus/broadcast-chat/send`, payload);
  },
  sendBroadcastMessageValidate(payload) {
    return this.post('/oauth/goldplus/broadcast-chat/validate', payload);
  },

  getOnboardingPreSubmission() {
    return this.get(`/oauth/goldplus/onboarding/pre-submission`);
  },
  getOnboardingSubmissionsFeatureGoldplus() {
    return this.get(`/oauth/goldplus/onboarding/submissions/features`);
  },

  getSubmissionPeriodeGP(packageCode) {
    return this.get(
      `/pms_api/v3/owner/goldplus/package/${packageCode}?weekly=true`
    );
  },

  submitUpgradeSubmissionGoldplus() {
    return this.post('/pms_api/v3/owner/goldplus/upgrade-subscription');
  },
};
