import Vue from 'vue'

import {
  getMatchedComponentsInstances,
  promisify,
  globalHandleError
} from './utils'

import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../assets/styles/desktop/index.scss'

const _5dd27e21 = () => import('../layouts/auth/index.vue'  /* webpackChunkName: "layouts/auth/index" */).then(m => m.default || m)
const _746ee6f6 = () => import('../layouts/default/data/sidebarItems.js'  /* webpackChunkName: "layouts/default/data/sidebarItems" */).then(m => m.default || m)
const _3d7810ca = () => import('../layouts/default/desktop/index.vue'  /* webpackChunkName: "layouts/default/desktop/index" */).then(m => m.default || m)
const _2db45b62 = () => import('../layouts/default/mixins/mixinLayoutDefault.js'  /* webpackChunkName: "layouts/default/mixins/mixinLayoutDefault" */).then(m => m.default || m)
const _6ba43f29 = () => import('../layouts/default/mobile/index.vue'  /* webpackChunkName: "layouts/default/mobile/index" */).then(m => m.default || m)
const _881ad284 = () => import('../layouts/input-property/desktop/index.vue'  /* webpackChunkName: "layouts/input-property/desktop/index" */).then(m => m.default || m)
const _38e3afa6 = () => import('../layouts/input-property/mobile/index.vue'  /* webpackChunkName: "layouts/input-property/mobile/index" */).then(m => m.default || m)
const _51123897 = () => import('../layouts/input-property/plugins/toast.js'  /* webpackChunkName: "layouts/input-property/plugins/toast" */).then(m => m.default || m)
const _6f6c098b = () => import('./layouts/default.vue'  /* webpackChunkName: "layouts/default" */).then(m => m.default || m)

const layouts = { "_auth/index": _5dd27e21,"_default/data/sidebarItems": _746ee6f6,"_default/desktop/index": _3d7810ca,"_default/mixins/mixinLayoutDefault": _2db45b62,"_default/mobile/index": _6ba43f29,"_input-property/desktop/index": _881ad284,"_input-property/mobile/index": _38e3afa6,"_input-property/plugins/toast": _51123897,"_default": _6f6c098b }

let resolvedLayouts = {}

export default {
  head: {"title":"Mamikos - Solusi Kos Tanpa Beban","meta":[{"charset":"utf-8"},{"hid":"viewport","name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Iklankan dan kelola usaha kos Anda secara online dengan mudah. Anda juga dapat iklankan apartemen."},{"hid":"og:tittle","name":"og:title","content":"Mamikos - Solusi Kos Tanpa Beban"},{"hid":"og:description","name":"og:description","content":"Iklankan dan kelola usaha kos Anda secara online dengan mudah. Anda juga dapat iklankan apartemen."},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"owner.mamikos.com"},{"hid":"author","name":"author","content":"PMS FE Developer"},{"hid":"theme-color","name":"theme-color","content":"#1BAA56"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"owner.mamikos.com"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","type":"text\u002Fcss","href":"\u002F\u002Fcdn.materialdesignicons.com\u002F2.4.85\u002Fcss\u002Fmaterialdesignicons.min.css"},{"rel":"manifest","href":"\u002F_nuxt\u002Fmanifest.730e6140.json"},{"rel":"shortcut icon","href":"\u002Fassets\u002Fmanifest\u002Ficon-72x72.png"},{"rel":"apple-touch-icon","href":"\u002Fassets\u002Fmanifest\u002Ficon-512x512.png","sizes":"512x512"}],"script":[{"src":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fpolyfill\u002Fv3\u002Fpolyfill.min.js?features=scroll%2CscrollIntoView","body":true}],"style":[],"htmlAttrs":{"lang":"id"}},

  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    if (this.nuxt.err && NuxtError) {
      const errorLayout = (NuxtError.options || NuxtError).layout
      if (errorLayout) {
        this.setLayout(
          typeof errorLayout === 'function'
            ? errorLayout.call(NuxtError, this.context)
            : errorLayout
        )
      }
    }

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: ''
  }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  mounted () {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    }
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map((page) => {
        const p = []

        if (page.$options.fetch) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail()
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },

    errorChanged () {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) {
          this.$loading.fail()
        }
        if (this.$loading.finish) {
          this.$loading.finish()
        }
      }
    },

    setLayout (layout) {
      if (!layout || !resolvedLayouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      let _layout = '_' + layout
      this.layout = resolvedLayouts[_layout]
      return this.layout
    },
    loadLayout (layout) {
      const undef = !layout
      const nonexistent = !(layouts['_' + layout] || resolvedLayouts['_' + layout])
      let _layout = '_' + ((undef || nonexistent) ? 'default' : layout)
      if (resolvedLayouts[_layout]) {
        return Promise.resolve(resolvedLayouts[_layout])
      }
      return layouts[_layout]()
        .then((Component) => {
          resolvedLayouts[_layout] = Component
          delete layouts[_layout]
          return resolvedLayouts[_layout]
        })
        .catch((e) => {
          if (this.$nuxt) {
            return this.$nuxt.error({ statusCode: 500, message: e.message })
          }
        })
    }
  },

  components: {
    NuxtLoading
  }
}
