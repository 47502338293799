export const state = () => ({
  statisticData: null,
  currentStatisticPage: null,
  personalStatisticData: null,
  storeWidgetData: {},
  trackerRedirection: '',
});

export const mutations = {
  setStatisticData(state, payload) {
    state.statisticData = payload;
  },

  setHistoryStatisticPage(state, payload) {
    state.currentStatisticPage = payload;
  },

  setPersonalStatisticData(state, payload) {
    state.personalStatisticData = payload;
  },

  setStoreWidgetData(state, payload) {
    state.storeWidgetData = payload;
  },

  setTrackerRedirection(state, payload) {
    state.trackerRedirection = payload;
  },
};

export const getters = {
  storeTitlePrice(state) {
    return state.personalStatisticData.price_title;
  },

  storePriceData(state) {
    return state.personalStatisticData.data_price;
  },

  // WIDGET
  storeWidgetSurvey(state) {
    return state.storeWidgetData.exit_gp_survey;
  },

  storeSurveyWidgetGP(state) {
    return state.storeWidgetData.exit_gp_survey.is_visible;
  },

  storeStatisticWidgetGP(state) {
    return state.storeWidgetData.exit_gp_statistic.is_visible;
  },

  storeStatisticData(state) {
    return state.storeWidgetData.exit_gp_statistic;
  },

  redirectionHistory(state) {
    return state.trackerRedirection;
  },
};
