import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

const initialContractFormData = () => ({
  contractDetails: null,
  rentTypeStore: '',
  rentDurationStore: 0,
  rentBasicAmountStore: 0,
  isAutoExtendStore: false,
  savedCostList: [], // Manage-contract local data
  unsavedCostList: [], // Manage-other-costs local data
});

export const state = () => ({
  isFetchingContractDetails: false,
  toast: { show: false, message: '' },
  ...initialContractFormData(),
});

export const mutations = {
  updateContractDetails(state, payload) {
    state.contractDetails = _cloneDeep(payload);
  },
  updateSavedCostList(state, payload) {
    state.savedCostList = _cloneDeep(payload);
  },
  updateUnsavedCostList(state, payload) {
    state.unsavedCostList = payload;
  },
  addUnsavedCostList(state) {
    state.unsavedCostList.push({
      id: null,
      name: '',
      amount: 0,
    });
  },
  removeUnsavedCost(state, index) {
    if (index > -1) state.unsavedCostList.splice(index, 1);
  },
  updateUnsavedCostName(state, params) {
    const { index, val } = params;
    state.unsavedCostList[index].name = val;
  },
  updateUnsavedCostAmount(state, params) {
    const { index, val } = params;
    state.unsavedCostList[index].amount = val;
  },
  saveCostChanges(state) {
    state.savedCostList = _cloneDeep(state.unsavedCostList);
  },
  resetCostChanges(state) {
    state.unsavedCostList = _cloneDeep(state.savedCostList);
  },
  setToastShow(state, show) {
    state.toast.show = !!show;
    if (!show) state.toast.message = '';
  },
  setToastMessage(state, message) {
    state.toast.message = message;
  },
  setIsFetchingContractDetails(state, fetching) {
    state.isFetchingContractDetails = !!fetching;
  },
  setRentDuration(state, duration) {
    state.rentDurationStore = duration;
  },
  setRentType(state, rentType) {
    state.rentTypeStore = rentType;
  },
  setRentBasicAmount(state, basicAmount) {
    state.rentBasicAmountStore = basicAmount;
  },
  setAutoExtendState(state, isAutoExtend) {
    state.isAutoExtendStore = !!isAutoExtend;
  },
  resetContractFormData(state) {
    const initialData = initialContractFormData();
    Object.keys(initialData).forEach(key => {
      state[key] = initialData[key];
    });
  },
};

export const getters = {
  isLocalAdditionalCostChanged(state) {
    return !_isEqual(state.savedCostList, state.unsavedCostList);
  },
  isAdditionalCostChanged(state) {
    return !_isEqual(
      state.savedCostList,
      _get(state.contractDetails, 'nearest_additional_costs', [])
    );
  },
  isRentDurationChanged(state) {
    return (
      state.rentDurationStore !==
      _get(state.contractDetails, 'contract_duration', 0)
    );
  },
  isRentTypeChanged(state) {
    return (
      state.rentTypeStore !==
      _get(state.contractDetails, 'contract_type_value', 0)
    );
  },
  isRentBasicAmountChanged(state) {
    return (
      state.rentBasicAmountStore !==
      _get(state.contractDetails, 'basic_amount', 0)
    );
  },
  isAutoExtendStateChanged(state) {
    return (
      state.isAutoExtendStore !==
      _get(state.contractDetails, 'is_auto_extend', false)
    );
  },
};

export const actions = {
  showToast({ commit }, { show = true, message = '' } = {}) {
    commit('setToastMessage', message);
    commit('setToastShow', show);
  },
  setupContractFormData({ commit }, data) {
    commit('updateContractDetails', data);

    commit('setRentType', data.contract_type_value);
    commit('setRentDuration', data.contract_duration);
    commit('setRentBasicAmount', data.basic_amount);
    commit('updateSavedCostList', data.nearest_additional_costs);
    commit('updateUnsavedCostList', data.nearest_additional_costs);
    commit('setAutoExtendState', !!data.is_auto_extend);
  },
};
