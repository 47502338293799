export default {
  updateAllProperties() {
    return this.post('/oauth/owner/data/update-room');
  },
  postSurveyRoom(data, params = {}) {
    return this.post('/oauth/owner/data/survey/updateroom', data, { params });
  },
  getActiveProperty(params, cancelToken = null) {
    return this.get('/oauth/owner/data/list/update', { params, cancelToken });
  },
  updatePropertyRoomPrice(data) {
    return this.post('/oauth/owner/update_room_price', data);
  },
  fetchAllPropertyRooms(params) {
    return this.get('/oauth/owner/data/kos', { params });
  },
  getPropertyRooms(params) {
    return this.get('/oauth/owner/data/kos/room-unit', { params });
  },
  deletePropertyRoom(param) {
    return this.delete(`/oauth/owner/data/kos/room-unit/single/${param}`);
  },
  postPropertyRoom(data) {
    return this.post('/oauth/owner/data/kos/room-unit/single', data);
  },
  updatePropertyRoom(data, roomId) {
    return this.put(`/oauth/owner/data/kos/room-unit/single/${roomId}`, data);
  },
  updateBulkPropertyRoom(data) {
    return this.post('/oauth/owner/data/kos/room-unit/bulk', data);
  },
  getPriceComponents(roomId) {
    return this.get(`/oauth/owner/room/price-component/${roomId}`);
  },
  postPriceComponentActivation(roomId, type, data) {
    return this.post(
      `/oauth/owner/room/price-component/toggle/${roomId}/${type}`,
      data
    );
  },
  postPriceComponent(roomId, data) {
    return this.post(`/oauth/owner/room/price-component/${roomId}`, data);
  },
  updatePriceComponent(priceId, data) {
    return this.put(`/oauth/owner/room/price-component/${priceId}`, data);
  },
  deletePriceComponent(priceId) {
    return this.delete(`/oauth/owner/room/price-component/${priceId}`);
  },
  getPropertyReviews(params) {
    return this.get('/oauth/owner/room/review', { params });
  },
  getLocationSuggestion(param, cancelToken = null) {
    return this.get(`/oauth/location-suggestion?criteria=${param}`, {
      cancelToken,
    });
  },
  postLocationSuggestionKeyword(data) {
    return this.post('/oauth/general-search/keyword', data);
  },
  // START -- Create/Edit Kos
  getProvince() {
    return this.get('/oauth/owner/data/kos/address/province');
  },
  getCity(name) {
    return this.get(`/oauth/owner/data/kos/address/city?province_name=${name}`);
  },
  getSubdistrict(name) {
    return this.get(`/oauth/owner/data/kos/address/subdistrict?city=${name}`);
  },
  getInputKosStageStatus(songId) {
    return this.get(`/oauth/owner/data/kos/input/stage/${songId}`);
  },
  postAddressStep(data) {
    return this.post('/oauth/owner/data/kos/input/address', data);
  },
  putAddressStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/address/${songId}`, data);
  },
  getAddressStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/address/${songId}`);
  },
  postInformationStep(data) {
    return this.post(`/oauth/owner/data/kos/input/information`, data);
  },
  putInformationStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/information/${songId}`, data);
  },
  getInformationStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/information/${songId}`);
  },
  getKosRules() {
    return this.get('/oauth/owner/data/kos/kos-rules');
  },
  getPhotoKostStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/kost-photo/${songId}`);
  },
  putPhotoKostStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/kost-photo/${songId}`, data);
  },
  putPhotoRoomStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/room-photo/${songId}`, data);
  },
  getPhotoRoomStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/room-photo/${songId}`);
  },
  getKosFacilities() {
    return this.get('/oauth/owner/data/kos/facilities');
  },
  putFasilityStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/facilities/${songId}`, data);
  },
  getFasilityStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/facilities/${songId}`);
  },
  putRoomAllotmentStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/room/${songId}`, data);
  },
  getRoomAllotmentStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/room/${songId}`);
  },
  putPriceStep(songId, data) {
    return this.put(`/oauth/owner/data/kos/input/price/${songId}`, data);
  },
  getPriceStep(songId) {
    return this.get(`/oauth/owner/data/kos/input/price/${songId}`);
  },
  getMinimumPayment() {
    return this.get('/oauth/owner/data/kos/minimum-payment');
  },
  putSubmitKos(songId) {
    return this.put(`/oauth/owner/data/kos/input/submit/${songId}`);
  },
  getAllProperty(filter) {
    return this.get(`/oauth/owner/data/property?filter=${filter}`);
  },
  getAllTypeByPropertyId(id) {
    return this.get(`/oauth/owner/data/property/${id}/type`);
  },
  postCheckPropertyTypeName(id, name) {
    return this.post(`/oauth/owner/data/property/${id}/validate-type-name`, {
      name,
    });
  },
  postCheckPropertyName(name) {
    return this.post('/oauth/owner/data/property/validate-name', { name });
  },
  postDuplicateKos(data) {
    return this.post('/oauth/owner/data/kos/duplicate', data);
  },
  postKosToBeReadyToVerify(songId) {
    return this.post(`/oauth/owner/data/kos/input/ready-to-verif/${songId}`);
  },
  // END -- Create/Edit Kos

  // START -- Auto BBK InfoBar Endpoint
  getKosManagementInfobar() {
    return this.get('/oauth/owner/kos-management-infobar');
  },
  postKosManagementVisited() {
    return this.post('/oauth/owner/kos-management-visit');
  },
  // END -- Auto BBK InfoBar Endpoint

  // START -- Booking Stay Setting
  getKosBookingStaySettingTopBbk() {
    return this.get(`/oauth/owner/booking/room/stay-setting/top-bbk`);
  },
  getKosBookingStaySetting(songId) {
    return this.get(`/oauth/owner/booking/room/stay-setting/${songId}`);
  },
  postKosBookingStaySetting(songId, data) {
    return this.post(`/oauth/owner/booking/room/stay-setting/${songId}`, data);
  },
  // END -- Booking Stay Setting

  getAvailabilityInfoVoucher() {
    return this.get('/pms_api/v3/owner/voucher/availability-info');
  },
};
