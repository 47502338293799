export const state = () => ({
  showMobileBackButton: false,
  prevPath: null,
  // This is temporary flag. Still waiting UXD decide it
  hideMobileFloatingChat: false,
  hideTopNavbar: false,
  topNavbarTitle: '',
  topNavbarType: '',
  hideBottomNavbar: false,
  bodyBackground: '',
  topNavbarAttributes: {},
  customPrevPath: null,
  topNavbarExternalLink: {},
  showMamikosGpLogo: false,
  mamikosGpLogoAnimate: false,
  wrapperBodyNoPadding: false,
});

export const mutations = {
  setShowMobileBackButton(state, isShown) {
    state.showMobileBackButton = isShown;
  },
  setPrevPath(state, prevPath) {
    state.prevPath = prevPath;
  },
  setCustomPrevPath(state, customPrevPath) {
    state.customPrevPath = customPrevPath;
  },
  setHideMobileFloatingChat(state, isHide) {
    state.hideMobileFloatingChat = isHide;
  },
  setBodyBackground(state, background) {
    state.bodyBackground = background;
  },
  setHideBottomNavbar(state, isHide) {
    state.hideBottomNavbar = isHide;
  },
  setHideTopNavbar(state, isHide) {
    state.hideTopNavbar = isHide;
  },
  setTopNavbarTitle(state, title) {
    state.topNavbarTitle = title;
  },
  setTopNavbarType(state, type) {
    state.topNavbarType = type;
  },
  setTopNavbarAttributes(state, attributes) {
    state.topNavbarAttributes = attributes;
  },
  setTopNavbarExternalLink(state, link) {
    state.topNavbarExternalLink = link;
  },
  setShowMamikosGpLogo(state, isShow) {
    state.showMamikosGpLogo = isShow;
  },
  setMamikosGpLogoAnimate(state, isShow) {
    state.mamikosGpLogoAnimate = isShow;
  },
  setWrapperBodyNoPadding(state, isNoPadding) {
    state.wrapperBodyNoPadding = isNoPadding;
  },
};
