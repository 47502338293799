export const state = () => ({
  booking_stay_setting: '',
});

export const mutations = {
  setVariant(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};
