function checkGPStatus(store, redirect) {
  const isGPActive = store.state.goldplus.isGoldPlusActive;
  const isGPOwner = store.state.profile.data.gp_owner;

  if (!isGPActive && !isGPOwner) return redirect('/');
}

export default ({ store, redirect, route }) => {
  const user = store.getters['profile/user'];

  if (!Object.keys(user).length) {
    store.dispatch('profile/getProfile').then(() => {
      checkGPStatus(store, redirect);
    });
  } else checkGPStatus(store, redirect);

  // Hide top navbar when access from webview android or ios
  if (route.query.webview === 'true') {
    store.commit('mobile/setHideTopNavbar', true);
  }
};
