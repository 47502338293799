export default {
  getMamitourFaq() {
    return this.get('/pms_api/v3/mamitour/faq');
  },
  getMamitourGuide() {
    return this.get('/pms_api/v3/mamitour/guide');
  },
  getMamitourHistoryList(params) {
    return this.get('/pms_api/v3/mamitour/order', { params });
  },
  getMamitourHistoryStatus() {
    return this.get('/pms_api/v3/mamitour/has-order');
  },
  getMamitourPackagePlan() {
    return this.get('/pms_api/v3/mamitour/package');
  },
  submitOrderMamitour(data) {
    return this.post('pms_api/v3/mamitour/order', data);
  },
};
