export default ({ store, route, app }) => {
  if (app.$device.isDesktop) return;

  store.commit('mobile/setShowMobileBackButton', false);
  store.commit('mobile/setPrevPath', null);
  store.commit('mobile/setHideMobileFloatingChat', false);
  store.commit('mobile/setHideBottomNavbar', false);
  store.commit('mobile/setHideTopNavbar', false);
  store.commit('mobile/setTopNavbarTitle', '');
  store.commit('mobile/setTopNavbarType', 'normal');
  store.commit('mobile/setTopNavbarAttributes', {});
  store.commit('mobile/setShowMamikosGpLogo', false);
  store.commit('mobile/setMamikosGpLogoAnimate', false);
  store.commit('mobile/setWrapperBodyNoPadding', false);

  route.meta.forEach(meta => {
    if (meta.showMobileBackButton || meta.prevPath) {
      store.commit('mobile/setHideTopNavbar', false);
      store.commit('mobile/setShowMobileBackButton', true);
      meta.prevPath && store.commit('mobile/setPrevPath', meta.prevPath);
    }

    if (meta.hideMobileFloatingChat) {
      store.commit('mobile/setHideMobileFloatingChat', true);
    }

    if (meta.mobileBodyBackground) {
      store.commit('mobile/setBodyBackground', meta.mobileBodyBackground);
    }

    if (meta.hideBottomNavbar) {
      store.commit('mobile/setHideBottomNavbar', true);
    }

    if (meta.hideTopNavbarMobile) {
      store.commit('mobile/setHideTopNavbar', true);
    }

    if (meta.topNavbarTitle) {
      store.commit('mobile/setHideTopNavbar', false);
      store.commit('mobile/setTopNavbarTitle', meta.topNavbarTitle);
    }

    if (meta.topNavbarType) {
      store.commit('mobile/setTopNavbarType', meta.topNavbarType);
    }

    if (!meta.showMobileBackButton && !meta.topNavbarTitle && !meta.prevPath) {
      store.commit('mobile/setHideTopNavbar', true);
    }

    if (meta.topNavbarAttributes) {
      store.commit('mobile/setTopNavbarAttributes', {
        ...meta.topNavbarAttributes,
      });
    }

    if (meta.showMamikosGpLogo) {
      store.commit('mobile/setShowMamikosGpLogo', true);
    }

    if (meta.wrapperBodyNoPadding) {
      store.commit('mobile/setWrapperBodyNoPadding', true);
    }
  });
};
