export const state = () => ({
  isInsufficientModalActive: false,
  isActiveListingModalActive: false,
  isActivateAdsSuccessModalActive: false,
  isConfirmationModalActive: false,
  isSetAdsBudgetModalActive: false,
  isChangeBudgetValidationModalActive: {
    status: false,
    title: '',
    description: '',
    id: 0,
  },
  selectedProperty: {},
  totalActiveAds: 0,
  premium: {},
});

export const mutations = {
  setInsufficientModalActive(state, payload) {
    state.isInsufficientModalActive = payload;
  },
  setHasActiveListingModal(state, payload) {
    state.isActiveListingModalActive = payload;
  },
  setActivateAdsSuccessModal(state, payload) {
    state.isActivateAdsSuccessModalActive = payload;
  },
  setConfirmationModal(state, payload) {
    state.isConfirmationModalActive = payload;
  },
  setChangeBudgetValidationModal(state, payload) {
    state.isChangeBudgetValidationModalActive = payload;
  },
  setTotalActiveAds(state, payload) {
    state.totalActiveAds = payload;
  },
  setPremium(state, payload) {
    state.premium = payload;
  },
};

export const actions = {
  async getPremiumProfile({ commit }) {
    try {
      const response = await this.$api.getPremiumProfileData();

      if (response.data.status) {
        commit('setPremium', response.data);
      } else {
        this.$error.report(JSON.stringify(response));
        this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
      }
    } catch (error) {
      this.$error.report(error);
      this.$alert('is-danger', 'Gagal', 'Terjadi galat, silakan coba lagi.');
    }
  },
};
