import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _539f3750 = () => interopDefault(import('../pages/ownerpage/_.vue' /* webpackChunkName: "pages/ownerpage/_" */))
const _346723da = () => interopDefault(import('~/routes/pages/booking/manage-status.vue' /* webpackChunkName: "pages/booking/manage/status" */))
const _26fb7a0b = () => interopDefault(import('~/routes/pages/booking/request/index.vue' /* webpackChunkName: "pages/booking/request/index" */))
const _31eb9475 = () => interopDefault(import('~/routes/pages/booking/request/list.vue' /* webpackChunkName: "pages/booking/request/list" */))
const _50c6d6e8 = () => interopDefault(import('~/routes/pages/booking/request/detail.vue' /* webpackChunkName: "pages/booking/request/detail" */))
const _52df562a = () => interopDefault(import('~/routes/pages/kos/rooms/index.vue' /* webpackChunkName: "pages/kos/rooms/_kos_id" */))
const _f4d9439c = () => interopDefault(import('~/components/page-features/rooms-edit/desktop' /* webpackChunkName: "pages/kos/rooms/_kos_id/index" */))
const _3f8c1db6 = () => interopDefault(import('~/components/page-features/rooms/components/PropertyRoomUpdateCard' /* webpackChunkName: "pages/kos/rooms-price/_kos_id/index" */))
const _5b645fe2 = () => interopDefault(import('~/routes/pages/kos/rooms/edit.vue' /* webpackChunkName: "pages/kos/rooms/_kos_id/edit" */))
const _044ccb21 = () => interopDefault(import('~/routes/pages/kos/rooms/price.vue' /* webpackChunkName: "pages/kos/rooms/_kos_id/price" */))
const _437a191d = () => interopDefault(import('~/routes/pages/kos/create.vue' /* webpackChunkName: "pages/kos/create" */))
const _5d42a5fa = () => interopDefault(import('~/routes/pages/kos/booking/register.vue' /* webpackChunkName: "pages/kos/booking/register" */))
const _22b1f32d = () => interopDefault(import('~/routes/pages/kos/booking/activation.vue' /* webpackChunkName: "pages/kos/booking/activation" */))
const _9a958dce = () => interopDefault(import('~/routes/pages/kos/booking/bbk-faq-webview.vue' /* webpackChunkName: "pages/webview/bbk-faq-webview" */))
const _71a1e88e = () => interopDefault(import('~/routes/pages/kos/booking/manfaat-booking-webview.vue' /* webpackChunkName: "pages/webview/manfaat-booking" */))
const _60089466 = () => interopDefault(import('~/routes/pages/kos/reviews/index.vue' /* webpackChunkName: "pages/kos/reviews" */))
const _6c574742 = () => interopDefault(import('~/routes/pages/statistic/StatisticMenu.vue' /* webpackChunkName: "pages/statistic/menu" */))
const _627cd61a = () => interopDefault(import('~/components/page-features/statistic/components/StatisticContainer/StatisticContainer.vue' /* webpackChunkName: "pages/statistic/listing_id/index" */))
const _51a8c902 = () => interopDefault(import('~/routes/pages/statistic/index.vue' /* webpackChunkName: "pages/statistic" */))
const _2e00901e = () => interopDefault(import('~/routes/pages/statistic/KosStatisticInfoPage.vue' /* webpackChunkName: "pages/statistic/review" */))
const _c3dcd82e = () => interopDefault(import('~/routes/pages/statistic/BenefitFromGpPage.vue' /* webpackChunkName: "pages/statistic/benefit-gp" */))
const _4ae8c728 = () => interopDefault(import('~/routes/pages/statistic/StatisticPricePage.vue' /* webpackChunkName: "pages/statistic/statistic-price" */))
const _687ca95e = () => interopDefault(import('~/routes/pages/statistic/OfferExtendGpPage.vue' /* webpackChunkName: "pages/statistic/offer-extend-gp" */))
const _3aa28e9c = () => interopDefault(import('~/routes/pages/statistic/SeeOtherPerformancePage.vue' /* webpackChunkName: "pages/statistic/see-other-performance" */))
const _4f16720d = () => interopDefault(import('~/routes/pages/contract/add-contract/index.vue' /* webpackChunkName: "pages/contract/add-contract" */))
const _9a903e8c = () => interopDefault(import('~/components/page-features/contract/add-contract/components/Onboarding' /* webpackChunkName: "pages/contract/add-contract/index" */))
const _51c08b6a = () => interopDefault(import('~/components/page-features/contract/add-contract/components/ContractType' /* webpackChunkName: "pages/contract/add-contract/index" */))
const _65a30674 = () => interopDefault(import('~/components/page-features/contract/add-contract/components/SelectKos' /* webpackChunkName: "pages/contract/add-contract/index" */))
const _51afc3a6 = () => interopDefault(import('~/components/page-features/contract/add-contract/components/FormSetting' /* webpackChunkName: "pages/contract/add-contract/index" */))
const _6d9b71fa = () => interopDefault(import('~/components/page-features/contract/add-contract/components/ShareLink' /* webpackChunkName: "pages/contract/add-contract/index" */))
const _10996eba = () => interopDefault(import('~/routes/pages/contract/contract-request/index.vue' /* webpackChunkName: "pages/contract/contract-request" */))
const _43abba45 = () => interopDefault(import('~/components/page-features/contract/contract-request/components/ContractList' /* webpackChunkName: "pages/contract/contract-request/index" */))
const _0c715790 = () => interopDefault(import('~/components/page-features/contract/contract-request/components/ContractDetail' /* webpackChunkName: "pages/contract/contract-request/index" */))
const _3d72f819 = () => interopDefault(import('~/routes/pages/contract/accept-request/index.vue' /* webpackChunkName: "pages/contract/accept-request" */))
const _bad00b22 = () => interopDefault(import('../routes/pages/premium/manage_ads.vue' /* webpackChunkName: "pages/premium-dashboard/_kos_id" */))
const _3fee0786 = () => interopDefault(import('../routes/pages/premium/promo.vue' /* webpackChunkName: "pages/premium-dashboard/_id/promo" */))
const _4094f242 = () => interopDefault(import('~/routes/pages/premium/manage_ads_index.vue' /* webpackChunkName: "pages/premium-dashboard/manage_ads_index" */))
const _20aca4b2 = () => interopDefault(import('../routes/pages/mamiads/index.vue' /* webpackChunkName: "pages/mamiads" */))
const _4939b52f = () => interopDefault(import('../routes/pages/mamiads/list-of-property/index.vue' /* webpackChunkName: "pages/mamiads/list-of-property" */))
const _f7b37efe = () => interopDefault(import('../routes/pages/mamiads/check-nearby-property/index.vue' /* webpackChunkName: "pages/mamiads/list-of-property" */))
const _710ef070 = () => interopDefault(import('../routes/pages/mamiads/transaction-history/index.vue' /* webpackChunkName: "pages/mamiads/history" */))

const _29e62d3f = () => interopDefault(import('../routes/pages/mamiads/balance/index.vue' /* webpackChunkName: "pages/mamiads/balance" */))
const _478d4006 = () => interopDefault(import('../routes/pages/mamiads/tenant/index.vue' /* webpackChunkName: "pages/mamiads/tenant" */))
const _ab0f0b7a = () => interopDefault(import('~/routes/pages/tenant-details/index.vue' /* webpackChunkName: "pages/tenant-details/_contract_id" */))

const _d4ddae92 = () => interopDefault(import('~/routes/pages/tenant-details/connect-contract/index.vue' /* webpackChunkName: "pages/tenant-details/_contract_id/connect-contract" */))
const _2ac6d4ee = () => interopDefault(import('~/routes/pages/tenant-details/manage-contract/index.vue' /* webpackChunkName: "pages/tenant-details/_contract_id/manage-contract/index" */))
const _54f220cd = () => interopDefault(import('~/routes/pages/tenant-details/manage-contract/main.vue' /* webpackChunkName: "pages/tenant-details/_contract_id/manage-contract/main" */))
const _5299db7a = () => interopDefault(import('~/routes/pages/tenant-details/manage-other-costs/index.vue' /* webpackChunkName: "pages/tenant-details/_contract_id/manage-contract/other-prices" */))
const _73869980 = () => interopDefault(import('~/routes/pages/refund/policy' /* webpackChunkName: "" */))
const _5e2e3b18 = () => interopDefault(import('../routes/pages/mamifoto/index.vue' /* webpackChunkName: "pages/mamifoto" */))
const _6650c997 = () => interopDefault(import('../routes/pages/mamifoto/packages.vue' /* webpackChunkName: "pages/packages" */))
const _1e9c16cc = () => interopDefault(import('../routes/pages/mamifoto/payment.vue' /* webpackChunkName: "pages/mamifoto/payment" */))
const _e1305b4c = () => interopDefault(import('../routes/pages/mamifoto/history.vue' /* webpackChunkName: "pages/mamifoto/history" */))
const _08e6b116 = () => interopDefault(import('../routes/pages/mamifoto/history-status.vue' /* webpackChunkName: "pages/mamifoto/history/status" */))
const _7eaeff6c = () => interopDefault(import('../routes/pages/mamitour/index.vue' /* webpackChunkName: "pages/mamitour" */))
const _da6e5ca4 = () => interopDefault(import('../routes/pages/mamitour/history.vue' /* webpackChunkName: "pages/mamitour/history" */))
const _61e0967a = () => interopDefault(import('../routes/pages/mamitour/packages.vue' /* webpackChunkName: "pages/mamitour/packages" */))
const _deab3814 = () => interopDefault(import('~/routes/pages/tenant-checker/index.vue' /* webpackChunkName: "pages/tenant/checker/" */))
const _7308f9b1 = () => interopDefault(import('../routes/pages/prime/index.vue' /* webpackChunkName: "pages/prime" */))
const _0c811536 = () => interopDefault(import('../routes/pages/prime/package.vue' /* webpackChunkName: "pages/prime/package" */))
const _a78efcd0 = () => interopDefault(import('~/components/page-features/prime/pages/package/components/PropertyPeriod/PropertyPeriod.vue' /* webpackChunkName: "pages/prime/package/listing_id/index" */))
const _67c19325 = () => interopDefault(import('../routes/pages/prime/payment.vue' /* webpackChunkName: "pages/prime/payment" */))
const _4ee5629a = () => interopDefault(import('../routes/pages/prime/history.vue' /* webpackChunkName: "pages/prime/history" */))
const _13280281 = () => interopDefault(import('../routes/pages/index.vue' /* webpackChunkName: "pages/dashboard" */))
const _dde9ef84 = () => interopDefault(import('../routes/pages/manage-kos/manage-kos.vue' /* webpackChunkName: "pages/manage-kos" */))
const _0124d9c7 = () => interopDefault(import('../routes/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _47514d78 = () => interopDefault(import('../routes/pages/mamipoin/index.vue' /* webpackChunkName: "pages/mamipoin" */))
const _55e31e4d = () => interopDefault(import('../routes/pages/mamipoin/rewards/index.vue' /* webpackChunkName: "pages/mamipoin/rewards" */))
const _5e6bc46f = () => interopDefault(import('../routes/pages/mamipoin/scheme.vue' /* webpackChunkName: "pages/mamipoin/scheme" */))
const _326a946c = () => interopDefault(import('../routes/pages/mamipoin/redeem/detail.vue' /* webpackChunkName: "pages/mamipoin/redeem/redeem_id" */))
const _02588a34 = () => interopDefault(import('../routes/pages/mamipoin/rewards/detail.vue' /* webpackChunkName: "pages/mamipoin/rewards/reward_id" */))
const _0d71cc78 = () => interopDefault(import('../routes/pages/mamipoin/rewards/exchange.vue' /* webpackChunkName: "pages/mamipoin/rewards/reward_id/exchange" */))
const _28d14344 = () => interopDefault(import('../routes/pages/goldplus/index.vue' /* webpackChunkName: "pages/goldplus" */))
const _8f785cd6 = () => interopDefault(import('../routes/pages/goldplus/payment/index.vue' /* webpackChunkName: "pages/goldplus/payment" */))
const _2eed1ec4 = () => interopDefault(import('../routes/pages/goldplus/payment/detail.vue' /* webpackChunkName: "pages/goldplus/payment/_id" */))
const _0d26d75f = () => interopDefault(import('../routes/pages/goldplus/statistic/index.vue' /* webpackChunkName: "pages/goldplus/statistic" */))
const _30072414 = () => interopDefault(import('../routes/pages/goldplus/statistic/detail.vue' /* webpackChunkName: "pages/goldplus/statistic/_id" */))
const _9871f276 = () => interopDefault(import('../routes/pages/goldplus/list/index.vue' /* webpackChunkName: "pages/goldplus/list" */))
const _d90a121a = () => interopDefault(import('../routes/pages/goldplus/submission/index.vue' /* webpackChunkName: "pages/goldplus/submission/" */))
const _0c25acde = () => interopDefault(import('../routes/pages/goldplus/guides/index.vue' /* webpackChunkName: "pages/goldplus/guides" */))
const _43192bb0 = () => interopDefault(import('../routes/pages/goldplus/guides/GuideDetailWebView.vue' /* webpackChunkName: "pages/goldplus/guides/webview/guideKey" */))
const _bda73c4e = () => interopDefault(import('../routes/pages/goldplus/guides/GuideDetail.vue' /* webpackChunkName: "pages/goldplus/guides/guideKey" */))
const _26fb419c = () => interopDefault(import('../routes/pages/goldplus/submission/stepper.vue' /* webpackChunkName: "pages/goldplus/submission/steps" */))
const _6457ce14 = () => interopDefault(import('../routes/pages/goldplus/submission/packages/detail.vue' /* webpackChunkName: "pages/goldplus/submission/package_code" */))
const _1718ed5f = () => interopDefault(import('../routes/pages/goldplus/submission/packages/index.vue' /* webpackChunkName: "pages/goldplus/submission/packages" */))
const _efed7136 = () => interopDefault(import('../routes/pages/goldplus/submission/subscribe-periode/index.vue' /* webpackChunkName: "pages/goldplus/periode/package_code" */))
const _e8ae7762 = () => interopDefault(import('../routes/pages/kelola-tagihan/index.vue' /* webpackChunkName: "pages/billing-management" */))
const _f66e8584 = () => interopDefault(import('../routes/pages/kelola-tagihan/search/index.vue' /* webpackChunkName: "pages/billing-management/search" */))
const _21c5c3e4 = () => interopDefault(import('../routes/pages/kelola-tagihan/detailBill/index.vue' /* webpackChunkName: "pages/billing-management/detailBill" */))
const _7d52cdfe = () => interopDefault(import('../routes/pages/survey/index.vue' /* webpackChunkName: "pages/survey" */))
const _3575a22b = () => interopDefault(import('../routes/pages/survey/questions-gp/questions.vue' /* webpackChunkName: "pages/survey/questions-gp" */))
const _60e8e278 = () => interopDefault(import('../routes/pages/survey/questions-gp/thanksSurveyPage.vue' /* webpackChunkName: "pages/survey/close" */))
const _24155fbe = () => interopDefault(import('../routes/pages/survey/questions-gp/offersGpPage.vue' /* webpackChunkName: "pages/survey/offers" */))
const _70237b53 = () => interopDefault(import('../routes/pages/tenant-list/index.vue' /* webpackChunkName: "pages/tenant-list" */))
const _2a417feb = () => interopDefault(import('../routes/pages/finance-report/index.vue' /* webpackChunkName: "pages/finance-report" */))
const _6d73c3b3 = () => interopDefault(import('../routes/pages/booking-setting' /* webpackChunkName: "pages/booking-setting" */))
const _dc3b3940 = () => interopDefault(import('../routes/pages/choose-property-type/index.vue' /* webpackChunkName: "pages/choose-property-type" */))
const _431fb32d = () => interopDefault(import('../routes/pages/broadcast-chat' /* webpackChunkName: "pages/broadcast-chat" */))
const _92c48410 = () => interopDefault(import('../routes/pages/broadcast-chat/select-kos.vue' /* webpackChunkName: "pages/broadcast-chat/select-kos" */))
const _e6f8eaea = () => interopDefault(import('../routes/pages/broadcast-chat/detail.vue' /* webpackChunkName: "pages/broadcast-chat/detail" */))
const _737b63cd = () => interopDefault(import('../routes/pages/broadcast-chat/detail-id.vue' /* webpackChunkName: "pages/broadcast-chat/detail-id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ownerpage/*",
    component: _539f3750,
    name: "ownerpage-all"
  }, {
    path: "/booking/manage/status",
    component: _346723da,
    name: "booking-manage-status"
  }, {
    path: "/booking/request",
    component: _26fb7a0b,
    children: [{
      path: "",
      component: _31eb9475,
      name: "booking-request-list"
    }, {
      path: ":bookingId",
      component: _50c6d6e8,
      name: "booking-request-detail"
    }]
  }, {
    path: "/kos/rooms",
    component: _52df562a,
    name: "kos-rooms",
    children: [{
      path: "/",
      component: _f4d9439c,
      name: "kos-rooms-index"
    }, {
      path: ":kos_id",
      component: _f4d9439c,
      name: "kos-rooms-kos_id"
    }]
  }, {
    path: "/kos/rooms-price",
    component: _52df562a,
    name: "kos-rooms-price",
    children: [{
      path: "/",
      component: _3f8c1db6,
      name: "kos-rooms-price-index"
    }, {
      path: ":kos_id",
      component: _3f8c1db6,
      name: "kos-rooms-price-kos_id"
    }]
  }, {
    path: "/kos/rooms/:kos_id/edit",
    component: _5b645fe2,
    name: "kos-rooms-kos_id-edit"
  }, {
    path: "/kos/rooms/:kos_id/price",
    component: _044ccb21,
    name: "kos-rooms-kos_id-price"
  }, {
    path: "/kos/create",
    component: _437a191d,
    name: "kos-create"
  }, {
    path: "/kos/edit",
    component: _437a191d,
    meta: {"isEdit":true},
    name: "kos-edit"
  }, {
    path: "/kos/booking/register",
    component: _5d42a5fa,
    name: "kos-booking-register"
  }, {
    path: "/kos/booking/activation",
    component: _22b1f32d,
    name: "kos-booking-activation"
  }, {
    path: "/bbk-faq-webview",
    component: _9a958dce,
    name: "bbk-faq-webview"
  }, {
    path: "/manfaat-booking-webview",
    component: _71a1e88e,
    name: "manfaat-booking-webview"
  }, {
    path: "/kos/reviews",
    component: _60089466,
    name: "kos-reviews"
  }, {
    path: "/statistic",
    component: _6c574742,
    name: "statistic-menu",
    children: [{
      path: "/statistic/:listing_id",
      component: _627cd61a,
      name: "statistic-menu-id"
    }]
  }, {
    path: "/exit-gp-statistic",
    component: _51a8c902,
    name: "statistic"
  }, {
    path: "/exit-gp-statistic/review",
    component: _2e00901e,
    name: "review"
  }, {
    path: "/exit-gp-statistic/benefit-gp",
    component: _c3dcd82e,
    name: "benefit-gp"
  }, {
    path: "/exit-gp-statistic/statistic-price",
    component: _4ae8c728,
    name: "statistic-price"
  }, {
    path: "/exit-gp-statistic/offer-extend-gp",
    component: _687ca95e,
    name: "offer-extend-gp"
  }, {
    path: "/exit-gp-statistic/see-other-performance",
    component: _3aa28e9c,
    name: "see-other-performance"
  }, {
    path: "/add-tenant",
    component: _4f16720d,
    name: "add-tenant",
    children: [{
      path: "/add-tenant/on-boarding",
      component: _9a903e8c,
      name: "add-tenant-onboarding"
    }, {
      path: "/add-tenant/select-method",
      component: _51c08b6a,
      name: "add-tenant-select-method"
    }, {
      path: "/add-tenant/from-tenant/choose-kos",
      component: _65a30674,
      name: "add-tenant-select-kost"
    }, {
      path: "/add-tenant/from-tenant/setting",
      component: _51afc3a6,
      name: "add-tenant-form-setting"
    }, {
      path: "/add-tenant/from-tenant/share-link",
      component: _6d9b71fa,
      name: "add-tenant-share-link"
    }]
  }, {
    path: "/contract-request",
    component: _10996eba,
    name: "contract-request",
    children: [{
      path: "/",
      component: _43abba45,
      meta: {"pageTitle":"Penyewa Mengajukan Kontrak"},
      name: "contract-request-list"
    }, {
      path: "/contract-request/detail/:id",
      component: _0c715790,
      meta: {"pageTitle":"Penyewa Mengajukan Kontrak"},
      name: "contract-request-detail"
    }]
  }, {
    path: "/contract-request/accept/:requestId",
    component: _3d72f819,
    name: "contract-request-accept"
  }, {
    path: "/premium-dashboard/kelola-iklan/:id",
    component: _bad00b22,
    meta: {"type":"premium-dashboard"},
    name: "premium-dashboard-kelola-iklan"
  }, {
    path: "/premium-dashboard/kelola-iklan/:id/promo",
    component: _3fee0786,
    meta: {"type":"premium-dashboard"},
    name: "premium-dashboard-kelola-promo"
  }, {
    path: "/premium-dashboard/kelola-iklan/",
    component: _4094f242,
    name: "premium-dashboard-kelola-iklan-index"
  }, {
    path: "/mamiads",
    component: _20aca4b2,
    meta: {"title":"Fitur Promosi - MamiAds"},
    name: "mami-ads"
  }, {
    path: "/mamiads/pilih-properti",
    component: _4939b52f,
    meta: {"title":"Pilih Properti - MamiAds"},
    name: "mami-ads-list-of-property"
  }, {
    path: "/cek-properti-sekitar",
    component: _f7b37efe,
    meta: {"title":"Fitur Promosi - Cek Property Sekitar"},
    name: "mami-ads-check-nearby-property"
  }, {
    path: "/mamiads/history",
    component: _710ef070,
    meta: {"title":"Fitur Promosi - Riwayat Transaksi"},
    name: "mami-ads-transaction-history",
    children: [{
      path: "/mamiads/history",
      name: "mami-ads-transaction-history-onprocess"
    }, {
      path: "/mamiads/history/paid",
      name: "mami-ads-transaction-history-done"
    }]
  }, {
    path: "/mamiads/balance",
    component: _29e62d3f,
    meta: {"title":"Beli Saldo - MamiAds"},
    name: "mami-ads-purchase-balance"
  }, {
    path: "/mamiads/tenant/:listing_id?",
    component: _478d4006,
    name: "mami-ads-tenant"
  }, {
    path: "/tenant-details/:contract_id",
    component: _ab0f0b7a,
    redirect: "/tenant-details/:contract_id/biodata",
    alias: ["/tenant-details/:contract_id/biodata","/tenant-details/:contract_id/contract"],
    name: "tenant-details",
    children: [{
      path: "/tenant-details/:contract_id/biodata",
      name: "tenant-details-biodata"
    }, {
      path: "/tenant-details/:contract_id/contract",
      name: "tenant-details-contract"
    }]
  }, {
    path: "/tenant-details/:contract_id/connect-contract",
    component: _d4ddae92,
    name: "tenant-details-connect-contract"
  }, {
    path: "/tenant-details/:contract_id/manage-contract",
    component: _2ac6d4ee,
    redirect: {"name":"tenant-details-manage-contract-main"},
    name: "tenant-details-manage-contract",
    children: [{
      path: "",
      component: _54f220cd,
      name: "tenant-details-manage-contract-main"
    }, {
      path: "other-prices",
      component: _5299db7a,
      name: "tenant-details-manage-other-prices"
    }]
  }, {
    path: "/refund/policy",
    component: _73869980,
    name: "refund-policy"
  }, {
    path: "/mamifoto",
    component: _5e2e3b18,
    name: "mamifoto"
  }, {
    path: "/mamifoto/packages",
    component: _6650c997,
    name: "mamifoto-packages"
  }, {
    path: "/mamifoto/payment",
    component: _1e9c16cc,
    name: "mamifoto-payment"
  }, {
    path: "/mamifoto/history",
    component: _e1305b4c,
    name: "mamifoto-history"
  }, {
    path: "/mamifoto/history/status/:id",
    component: _08e6b116,
    name: "mamifoto-history-status-id"
  }, {
    path: "/mamitour",
    component: _7eaeff6c,
    name: "mamitour"
  }, {
    path: "/mamitour/history",
    component: _da6e5ca4,
    name: "mamitour-history"
  }, {
    path: "/mamitour/package",
    component: _61e0967a,
    name: "mamitour-packages"
  }, {
    path: "/tenant/checker/:tenantId?",
    component: _deab3814,
    name: "tenant-background-checker"
  }, {
    path: "/prime",
    component: _7308f9b1,
    name: "prime"
  }, {
    path: "/prime/package",
    component: _0c811536,
    name: "prime-package",
    children: [{
      path: "/prime/package/:listing_id",
      component: _a78efcd0,
      name: "prime-package-id"
    }]
  }, {
    path: "/prime/payment",
    component: _67c19325,
    name: "prime-payment"
  }, {
    path: "/prime/history",
    component: _4ee5629a,
    name: "prime-history"
  }, {
    path: "/",
    component: _13280281,
    name: "index"
  }, {
    path: "/manage-kos",
    component: _dde9ef84,
    name: "manage-kos"
  }, {
    path: "/version",
    component: _0124d9c7,
    name: "version"
  }, {
    path: "/check-nearby-property",
    component: _f7b37efe,
    name: "check-nearby-property"
  }, {
    path: "/mamipoin",
    component: _47514d78,
    name: "mamipoin"
  }, {
    path: "/mamiads",
    component: _20aca4b2,
    name: "mamiads"
  }, {
    path: "/mamipoin/rewards",
    component: _55e31e4d,
    name: "mamipoin-rewards"
  }, {
    path: "/mamipoin/scheme",
    component: _5e6bc46f,
    name: "mamipoin-scheme"
  }, {
    path: "/mamipoin/redeem/:redeem_id",
    component: _326a946c,
    name: "mamipoin-redeem-detail"
  }, {
    path: "/mamipoin/rewards/:reward_id",
    component: _02588a34,
    name: "mamipoin-rewards-detail"
  }, {
    path: "/mamipoin/rewards/:reward_id/exchange",
    component: _0d71cc78,
    name: "mamipoin-rewards-exchange"
  }, {
    path: "/goldplus",
    component: _28d14344,
    name: "goldplus"
  }, {
    path: "/goldplus/payment",
    component: _8f785cd6,
    name: "goldplus-payment"
  }, {
    path: "/goldplus/payment/:id",
    component: _2eed1ec4,
    name: "goldplus-payment-id"
  }, {
    path: "/goldplus/statistic",
    component: _0d26d75f,
    name: "goldplus-statistic"
  }, {
    path: "/goldplus/statistic/:id",
    component: _30072414,
    name: "goldplus-statistic-id"
  }, {
    path: "/goldplus/list",
    component: _9871f276,
    name: "goldplus-list"
  }, {
    path: "/goldplus/submission",
    component: _d90a121a,
    name: "goldplus-submission"
  }, {
    path: "/goldplus/guides",
    component: _0c25acde,
    name: "goldplus-guides"
  }, {
    path: "/goldplus/guides/webview/:guideKey",
    component: _43192bb0,
    meta: {"WebView":true},
    name: "goldplus-guides-detail-webview"
  }, {
    path: "/goldplus/guides/:guideKey",
    component: _bda73c4e,
    name: "goldplus-guides-detail"
  }, {
    path: "/goldplus/submission/onboarding",
    component: _d90a121a,
    redirect: "/goldplus/submission",
    name: "goldplus-submission-onboarding-old"
  }, {
    path: "/goldplus/submission/steps",
    component: _26fb419c,
    name: "goldplus-submission-steps"
  }, {
    path: "/goldplus/submission/packages/:code",
    component: _6457ce14,
    name: "goldplus-submission-package-code"
  }, {
    path: "/goldplus/submission/packages",
    component: _1718ed5f,
    name: "goldplus-packages"
  }, {
    path: "/goldplus/submission/periode/:code",
    component: _efed7136,
    name: "goldplus-submission-subscribe-periode-code"
  }, {
    path: "/billing-management",
    component: _e8ae7762,
    meta: {"title":"Manajemen Kos - Kelola tagihan Anda"},
    name: "billing-management"
  }, {
    path: "/billing-management/search",
    component: _f66e8584,
    meta: {"title":"Manajemen Kos - Kelola tagihan Anda"},
    name: "billing-management-search"
  }, {
    path: "/billing-management/:id/detail-bill",
    component: _21c5c3e4,
    meta: {"title":"Manajemen Kos - Kelola tagihan Anda"},
    name: "billing-management-id-detail-bill"
  }, {
    path: "/kelola-tagihan",
    redirect: "/billing-management",
    name: "kelola-tagihan"
  }, {
    path: "/kelola-tagihan/:id/detail-tagihan",
    redirect: "/billing-management/:id/detail-bill",
    name: "kelola-tagihan-id-detail-tagihan"
  }, {
    path: "/exit-gp-survey",
    component: _7d52cdfe,
    name: "survey"
  }, {
    path: "/exit-gp-survey/feedback",
    component: _3575a22b,
    name: "feedback"
  }, {
    path: "/exit-gp-survey/close",
    component: _60e8e278,
    name: "close"
  }, {
    path: "/exit-gp-survey/offers",
    component: _24155fbe,
    name: "change-packet"
  }, {
    path: "/tenant-list",
    component: _70237b53,
    meta: {"title":"Manajemen Kos - List penyewa Anda"},
    name: "tenant-list"
  }, {
    path: "/financial-report",
    component: _2a417feb,
    meta: {"title":"Manajemen Kos - Laporan keuangan Anda"},
    name: "finance-report"
  }, {
    path: "/booking-setting",
    component: _6d73c3b3,
    name: "booking-setting"
  }, {
    path: "/choose-property-type",
    component: _dc3b3940,
    name: "choose-property-type"
  }, {
    path: "/broadcast-chat",
    component: _431fb32d,
    name: "broadcast-chat"
  }, {
    path: "/broadcast-chat/kos",
    component: _92c48410,
    name: "broadcast-chat-select-kos"
  }, {
    path: "/broadcast-chat/detail",
    component: _e6f8eaea,
    name: "broadcast-chat-detail"
  }, {
    path: "/broadcast-chat/detail/:id",
    component: _737b63cd,
    name: "broadcast-chat-detail-id"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
