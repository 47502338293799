export const INVOICE_STATUS = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  TRANSFERRED: 'transferred',
  HAS_PAID: 'has-paid',
};

export const INVOICE_TYPE = {
  FULL_PAYMENT: 'full_payment',
  DOWN_PAYMENT: 'down_payment',
  SETTLEMENT: 'settlement',
  RECURRING: 'recurring',
};

export const INVOICE_TYPE_LABEL = {
  [INVOICE_TYPE.FULL_PAYMENT]: 'Bayar Sewa',
  [INVOICE_TYPE.DOWN_PAYMENT]: 'Uang Muka',
  [INVOICE_TYPE.SETTLEMENT]: 'Pelunasan',
  [INVOICE_TYPE.RECURRING]: 'Bayar Sewa',
};
