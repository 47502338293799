import Cookies from 'js-cookie';

export const state = () => ({
  data: {},
  notifications: [],
  bbkChargingAnnouncement: false,
  loading: false,
  invisibleSidebars: [],
  blacklistRouterNames: [],
  abTest: {
    is_active: false,
    experiment_id: 0,
    experiment_value: '',
  },
  isMamipayBookingAvailable: false,
  ownerInvoice: {},

  roomTypeActiveCount: null,
});

const modalGetters = {
  showModalPremiumTrial(state) {
    const {
      user: { can_trial, kost_total_active, apartment_total_active, is_verify },
    } = state.data;
    // TODO: need to set cookie with non empty string instead of using current mamikos.com way
    return (
      can_trial &&
      (kost_total_active || apartment_total_active) &&
      is_verify &&
      Cookies.get('pop-up-trial') === undefined
    );
  },
  showModalSurveyPremium(state) {
    const {
      user: { is_verify },
      survey,
    } = state.data;
    return is_verify && survey;
  },
  showModalRoomSurvey(state) {
    return state.data.user.is_verify && state.needRoomSurvey;
  },
  showModalNeedPassword(state) {
    return state.data.user.need_password;
  },
  showModalVerifyPhone(state) {
    return !state.data.user.is_verify;
  },
  showModalDailyBalanceAllocation(state, getters) {
    if (!getters.isEligibleToDailyAllocation) return false;
    return (
      !getters.isOnDailyAllocation &&
      state.data.user.show_popup_daily_allocation
    );
  },
  /* eslint-enable */
};

const userGetters = {
  activeKos(state, getters) {
    return getters.user.kost_total_active;
  },
  activeApartment(state, getters) {
    return getters.user.apartment_total_active;
  },
  isUserVerified(state, getters) {
    return getters.user.is_verify;
  },
  userHasNoProperty(state, getters) {
    return getters.user.kost_total === 0;
  },
  userHasNoApartment(state, getters) {
    return getters.user.apartment_total === 0;
  },
  isNewOwner(_, getters) {
    return getters.activeKos === 0;
  },
  userWaitsPropertyConfirmation(state, getters) {
    return getters.user.kost_total > 0 && getters.user.kost_total_active === 0;
  },
  userWaitsApartmentConfirmation(state, getters) {
    return (
      getters.user.apartment_total > 0 &&
      getters.user.apartment_total_active === 0
    );
  },
  user(state) {
    const { user = {} } = state.data;
    return user;
  },
  isSsOwnerCp(state) {
    return state.data.is_ss_owner_cp;
  },
  isOnAbTesting(state) {
    return state.abTest.is_active && state.abTest.experiment_id > 0;
  },
  abTestingScenario(state, getters) {
    if (!getters.isOnAbTesting) return false;
    return state.abTest.experiment_value === 'control' ? 'A' : 'B';
  },
  // Owner is on B scenario in AB Testing
  isOnBScenario(state, getters) {
    return getters.isOnAbTesting && getters.abTestingScenario === 'B';
  },

  hasActiveProperty(_, getters) {
    return getters.activeKos > 0 || getters.activeApartment > 0;
  },

  isAgent() {
    const agentRoles = {
      business_development: 'BD',
      market_development_representative: 'MDR',
      agent_offline: 'AO',
    };

    return agentRoles[Cookies.get('agent_role')];
  },
};

const membershipGetters = {
  isBbk(state, getters) {
    // Is_booking_all_room = there is one kos that has bkk
    // Is_mamipay_user = owner is mamipay user already
    return getters.membership.is_mamipay_user && state.data.is_booking_all_room;
  },
  isPremium(state, getters) {
    return (
      !getters.membership.expired && !!(getters.membership.status === 'Premium')
    );
  },
  membership(state) {
    const { membership = {} } = state.data;
    return membership;
  },
  roomCount(state) {
    const { room_count = 0 } = state.data;
    return room_count;
  },
  isOnDailyAllocation(state, getters) {
    return getters.membership.daily_allocation;
  },
  isEligibleToDailyAllocation(state, getters) {
    return getters.isPremium && getters.membership.balance_status === 'verif';
  },
};

const roomAvailabilityGetters = {
  roomAvailability(state) {
    const { room_availability = {} } = state.data;
    return room_availability;
  },
};

const bookingGetters = {
  bookings(state) {
    const { bookings = {} } = state.data;
    return bookings;
  },
  isBooking(state) {
    return state.data.is_booking_all_room;
  },
  isBookingAvailable(state) {
    const { booking_status } = state.isMamipayBookingAvailable;
    return booking_status;
  },
};

const notificationGetters = {
  notification(state, getters) {
    return {
      list: state.notifications.slice(0, 5),
      count: getters.user.notification_count,
    };
  },
};

const viewGetters = {
  getInvisibleSidebars(state) {
    return state.invisibleSidebars;
  },
  getBlacklistRouterNames(state) {
    return state.blacklistRouterNames;
  },
};

// For get the total_kos & total_apartemen from API getRoomTypeActive
const roomTypeActive = {
  totalActiveKos(state) {
    return state.roomTypeActiveCount?.total_kost;
  },

  totalActiveApartemen(state) {
    return state.roomTypeActiveCount?.total_apartment;
  },

  hasActiveKos(_, getters) {
    return getters.totalActiveKos > 0;
  },

  hasActiveApartmentOnly(_, getters) {
    return getters.totalActiveKos === 0 && getters.totalActiveApartemen > 0;
  },
};

export const getters = {
  ...modalGetters,
  ...bookingGetters,
  ...membershipGetters,
  ...notificationGetters,
  ...roomAvailabilityGetters,
  ...userGetters,
  ...viewGetters,
  ...roomTypeActive,
};

export const mutations = {
  updateNotification(state, notifications) {
    state.notifications = notifications;
  },
  updateProfile(state, profile) {
    state.data = profile;
  },
  updateLoading(state, stateLoading) {
    state.loading = stateLoading;
  },
  updateDailyAllocation(state, dailyAllocation) {
    state.data.membership.daily_allocation = dailyAllocation;
  },
  updateInvisibleSidebars(state, invisibleSidebars = []) {
    state.invisibleSidebars = invisibleSidebars;
  },
  updateBlacklistRouterNames(state, blacklistRouterNames = []) {
    state.blacklistRouterNames = blacklistRouterNames;
  },
  updateBbkChargingAnnoucement(state, showAnnouncement) {
    state.bbkChargingAnnouncement = showAnnouncement;
  },
  setAbTest(state, abTestResult) {
    state.abTest = abTestResult;
  },
  updateMamipayBookingAvailable(state, bookingAvailable) {
    state.isMamipayBookingAvailable = bookingAvailable;
  },
  setOwnerInvoice(state, invoice) {
    state.ownerInvoice = invoice;
  },
  setRoomTypeActive(state, payload) {
    state.roomTypeActiveCount = payload;
  },
};

export const actions = {
  async getProfile({ commit, dispatch }) {
    commit('updateLoading', true);

    const { user, membership, is_blacklist_mamipoin } = await dispatch(
      'getLatestProfile'
    );
    const isBlacklistMamipoin = !!is_blacklist_mamipoin;
    dispatch('checkAbTest');

    if (isBlacklistMamipoin) {
      commit('updateBlacklistRouterNames', [
        'mamipoin',
        'mamipoin-scheme',
        'mamipoin-rewards',
        'mamipoin-rewards-detail',
        'mamipoin-redeem-detail',
        'mamipoin-rewards-exchange',
      ]);
      commit('updateInvisibleSidebars', ['MamiPoin']);
    }

    const ownerChargingRes = await this.$api.getOwnerChargingInfo();
    ownerChargingRes.data.status &&
      commit(
        'updateBbkChargingAnnoucement',
        ownerChargingRes.data.announcement.bbk_charging
      );

    this.dispatch('goldplus/getActiveStatus');
    this.$tracker.init(user, membership);

    commit('updateLoading', false);
  },
  async getNotifications({ commit }) {
    const resNotif = await this.$api.getNotification();
    commit('updateNotification', resNotif.data.data);
  },
  async getLatestProfile({ commit }) {
    const res = await this.$api.getProfile();
    const ownerInvoice = await this.$api.getOwnerInvoice();
    commit('updateProfile', res.data);
    commit('updateDailyAllocation', res.data.membership.daily_allocation);
    commit('setOwnerInvoice', ownerInvoice.data);

    // Set agent data to Cookies for Mamikos LCT
    Cookies.set(
      'agent_data',
      {
        name: res.data.user.name,
        photo: res.data.user.photo,
      },
      {
        domain: process.env.OAUTH2_DOMAIN,
        samesite: 'strict',
      }
    );

    return res.data;
  },
  async updateDailyBalanceAllocation({ commit, getters }, params) {
    if (params === 'cancel') {
      const current = getters.isOnDailyAllocation;
      commit('updateDailyAllocation', !current);
      //  This was meant to quickly update the switch display on premiumcard
      setTimeout(() => {
        commit('updateDailyAllocation', current);
      }, 10);
    } else {
      const res = await this.$api.getDailyActivationAllocation();
      if (res.data.status) {
        if (params === 'activation') {
          commit('updateDailyAllocation', true);
        } else {
          commit('updateDailyAllocation', false);
        }
      }
    }
  },
  async checkAbTest({ commit }) {
    const res = await this.$api.checkAbTestStatus();
    commit('setAbTest', res.data.result);
  },
  async getMamipayBookingAvailable({ commit }) {
    const res = await this.$api.getMamipayBookingAvailable();
    commit('updateMamipayBookingAvailable', res.data.data);
  },
  async getRoomTypeActive({ commit }) {
    try {
      const response = await this.$api.getRoomTypeActive();
      if (response) {
        commit('setRoomTypeActive', response.data);
      }
    } catch (error) {
      this.$error.report(error);
    }
  },
};
