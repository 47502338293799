export default {
  /**
   * Get owner's rooms by booking status
   * @param { booking_status: String, offset: Number, limit: Number } params
   */
  getMamipayRooms(params) {
    return this.get('/oauth/mamipay/request/rooms', { params });
  },
  getMamipayRoom(id) {
    return this.get(`/oauth/mamipay/request/room/detail/${id}`);
  },
  getBankList() {
    return this.get('/oauth/mamipay/request/bank/list');
  },
  getMamipayDetail() {
    return this.get('/oauth/mamipay/request/owner/detail');
  },
  registerDataBbk(data) {
    return this.post(
      '/oauth/mamipay/request/auth/owner/ver2/register-bbk',
      data
    );
  },
  getBillingSummary() {
    return this.get('oauth/mamipay/request/owner/billing/summary');
  },
  getBillingSummaryPerStatus({ status, year, month, songId, rentType }) {
    return this.get(
      `oauth/mamipay/request/owner/billing/${songId}/${status}/${year}/${month}/summary?rent_type=${rentType}`
    );
  },
  getBillListInvoices(
    {
      status,
      year,
      month,
      songId,
      rentType,
      limit,
      offset,
      sortBy,
      sortOrder,
      tenantName,
      filterType,
    },
    cancelToken = null
  ) {
    return this.get(
      `oauth/mamipay/request/owner/billing/${songId}/${status}/${year}/${month}`,
      {
        cancelToken,
        params: {
          limit,
          offset,
          rent_type: rentType,
          sort_by: sortBy,
          sort_order: sortOrder,
          tenant_name: tenantName,
          filter_type: filterType,
        },
      }
    );
  },
  sendReminder(invoiceId, data = {}) {
    return this.post(
      `oauth/mamipay/request/payment-reminder/${invoiceId}`,
      data
    );
  },
  sendBulkReminder(data = {}) {
    return this.post(`oauth/mamipay/request/payment-reminder/bulk`, data);
  },
  getDetailInvoice(invoiceId) {
    return this.get(`oauth/mamipay/request/owner/billing/detail/${invoiceId}`);
  },
  getBillingTimeline(invoiceId) {
    return this.get(`oauth/mamipay/request/owner/billing/status/${invoiceId}`);
  },
  updatePriceRent(invoiceId, data = {}) {
    return this.post(
      `oauth/mamipay/request/invoice/edit/amount/${invoiceId}`,
      data
    );
  },
  updateAdditionalPrice(data = {}) {
    return this.post(
      `oauth/mamipay/request/additional-cost-field/invoice/bulk-create`,
      data
    );
  },
  setManualPayment(invoiceId) {
    return this.put(`oauth/mamipay/request/set-manual-payment/${invoiceId}`);
  },
  getMamipayBookingAvailable() {
    return this.get('/oauth/mamipay/request/owner/booking/available');
  },
  getPaymentScheduleReport({ roomId = '', year = '', month = '' } = {}) {
    return this.get(
      `/oauth/mamipay/request/payment-schedules/amount-report/${roomId}/${year}/${month}`
    );
  },
  getBookingRejectReason() {
    return this.get('/oauth/mamipay/request/owner/booking/reject/reason');
  },
  postBookingReject({ bookingId, params } = {}) {
    return this.post(
      `/oauth/mamipay/request/owner/booking/reject/${bookingId}`,
      params
    );
  },
  getOnboardingGoldPlusManageBooking() {
    return this.get(
      `/oauth/mamipay/request/onboarding/goldplus/kelola-booking`
    );
  },
};
