import _includes from 'lodash/includes';

const validateUserHaveKos = store => {
  const activeKos = store.getters['profile/activeKos'];
  if (activeKos === 0) {
    window.location.href = '/?showModalKosActiveGp=true';
  }
};

/* eslint-disable func-style */
function checkGPSubmissionStatus(store, route, redirect) {
  let gpStatus;
  const isPackageDetailPage = route.name === 'goldplus-submission-package-code';
  const isUpgrade = route.query?.gp_upgrade_modal;
  const goldplusProperties = store.state.goldplus.goldplusProperties?.gp_status;

  if (goldplusProperties) {
    gpStatus = store.state.goldplus.goldplusProperties?.gp_status?.key;
  }

  const isGPActive = !!(
    gpStatus &&
    !_includes(['register', 'review', 'waiting', 'waiting_multiple'], gpStatus)
  );

  // If user have gp active
  if (isGPActive) {
    // Only GP2 User &&
    // In Page Detail Tagihan will be redirect to goldplus dashboard page
    if (isPackageDetailPage && isUpgrade) {
      if (gpStatus === 'gp2' || gpStatus === 'waiting_recurring_gp2') {
        return redirect('/goldplus');
      }
    } else {
      return redirect('/goldplus');
    }
  } else {
    const user = store.getters['profile/user'];

    if (!Object.keys(user).length) {
      store.dispatch('profile/getProfile').then(() => {
        validateUserHaveKos(store);
      });
    } else validateUserHaveKos(store);
  }
}

export default ({ store, route, redirect }) => {
  const user = store.getters['profile/user'];

  if (!Object.keys(user).length) {
    store.dispatch('goldplus/getActiveStatus').then(() => {
      store.dispatch('goldplus/getGoldPlusSubmissionStatus').then(() => {
        checkGPSubmissionStatus(store, route, redirect);
      });
    });
  } else {
    store.dispatch('goldplus/getGoldPlusSubmissionStatus').then(() => {
      checkGPSubmissionStatus(store, route, redirect);
    });
  }
};
