import { INVOICE_STATUS } from '~/constants/invoice';
import { appendRooms } from '~/components/page-features/kelola-tagihan/utils/appendRooms';

const getInitialBillingCardSummary = type => {
  if (type === INVOICE_STATUS.UNPAID) {
    return {
      loading: false,
      amount: 0,
      status_count: 0,
      total_count: 0,
    };
  }

  if (type === INVOICE_STATUS.HAS_PAID) {
    return {
      loading: false,
      amount_disbursed: 0,
      amount: 0,
      processed_invoice_count: 0,
      outside_mamipay_count: 0,
      total_count: 0,
    };
  }

  return {};
};

const getMirroredInvoiceStatus = status => {
  if (status === INVOICE_STATUS.PAID) {
    return INVOICE_STATUS.HAS_PAID;
  }

  return status;
};

export const state = () => ({
  activePage: '',
  selectedKost: {
    songId: '',
    kostName: '',
  },
  filterOption: {
    month: '',
    year: '',
    rentType: 'all',
    status: 'unpaid',
    sortBy: 'scheduled_date',
    sortOrder: 'asc',
    tenantName: '',
    filterType: '',
  },
  kostList: [],
  kostListPagination: {
    offset: 0,
    hasMore: true,
  },
  billingCardSummary: {
    [INVOICE_STATUS.UNPAID]: getInitialBillingCardSummary(
      INVOICE_STATUS.UNPAID
    ),
    [INVOICE_STATUS.HAS_PAID]: getInitialBillingCardSummary(
      INVOICE_STATUS.HAS_PAID
    ),
  },
  pagination: {
    isLoading: false,
    totalData: 0,
    pageNumber: 0,
    limit: 10,
    offset: 0,
    data: [],
  },
});

export const mutations = {
  updateSelectedKost(state, { songId, kostName }) {
    state.selectedKost.songId = songId;
    state.selectedKost.kostName = kostName;
  },
  updateFilterDate(state, { month, year }) {
    state.filterOption.month = month;
    state.filterOption.year = year;
  },
  updateFilterRentType(state, payload) {
    state.filterOption.rentType = payload;
  },
  updateFilterTenantName(state, payload) {
    state.filterOption.tenantName = payload;
  },
  updateFilterSortBy(state, payload) {
    state.filterOption.sortBy = payload;
  },
  updateFilterStatus(state, payload) {
    state.filterOption.status = payload;
  },
  updateFilterFilterType(state, payload) {
    state.filterOption.filterType = payload;
  },
  updateFilterInitial(state, payload) {
    state.filterOption = { ...payload };
  },
  setKostList(state, payload) {
    state.kostList = payload;
  },
  addKostList(state, payload) {
    state.kostList = appendRooms(state.kostList, payload);
  },
  setKostListPagination(state, payload) {
    state.kostListPagination = payload;
  },
  setBillingSummaryUnpaid(state, payload) {
    state.billingCardSummary[INVOICE_STATUS.UNPAID] = payload;
  },
  setBillingSummaryHasPaid(state, payload) {
    state.billingCardSummary[INVOICE_STATUS.HAS_PAID] = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setPaginationData(state, payload) {
    state.pagination.data = payload;
  },
  setPaginationLoading(state, payload) {
    state.pagination.isLoading = payload;
  },
  setPaginationTotalData(state, payload) {
    state.pagination.totalData = payload;
  },
  setPaginationOffset(state, payload) {
    state.pagination.offset = payload;
  },
  setPaginationPageNumber(state, payload) {
    state.pagination.pageNumber = payload;
  },
  setBillingSummaryProps(state, { type, props }) {
    const selectedBillingCardSummary = state.billingCardSummary[type];

    if (selectedBillingCardSummary) {
      state.billingCardSummary[type] = {
        ...selectedBillingCardSummary,
        ...props,
      };
    }
  },
  setBillingSummaryLoading(state, { type, isLoading }) {
    if (state.billingCardSummary[type]) {
      state.billingCardSummary[type].loading = isLoading;
    }
  },
};

export const actions = {
  clearBillingSummaryStatus({ commit }) {
    commit(
      'setBillingSummaryUnpaid',
      getInitialBillingCardSummary(INVOICE_STATUS.UNPAID)
    );
    commit(
      'setBillingSummaryHasPaid',
      getInitialBillingCardSummary(INVOICE_STATUS.HAS_PAID)
    );
  },

  getBillingSummaryPerStatus({ commit, state }, params) {
    const { status, year, month, rentType } = state.filterOption;
    const { songId } = state.selectedKost;

    if (!songId) return;

    const statusBilling = getMirroredInvoiceStatus(params || status);

    commit('setBillingSummaryProps', {
      type: statusBilling,
      props: {
        ...getInitialBillingCardSummary(statusBilling),
        loading: true,
      },
    });

    this.$api
      .getBillingSummaryPerStatus({
        status: statusBilling,
        year,
        month,
        songId,
        rentType: rentType === 'all' ? '' : rentType,
      })
      .then(response => {
        const { status, data } = response.data;
        if (status && data) {
          commit('setBillingSummaryProps', {
            type: statusBilling,
            props: data,
          });
        }
      })
      .finally(() => {
        commit('setBillingSummaryProps', {
          type: statusBilling,
          props: { loading: false },
        });
      });
  },
  getBillListInvoices({ commit, state }, { invoiceStatus, pageNumber }) {
    const { limit } = state.pagination;
    commit('setPaginationData', []);
    commit('setPaginationLoading', true);
    commit(
      'setPaginationOffset',
      // eslint-disable-next-line no-mixed-operators
      pageNumber === 1 ? 0 : limit * pageNumber - limit
    );
    commit('setPaginationPageNumber', pageNumber);

    const {
      status,
      year,
      month,
      rentType,
      sortBy,
      sortOrder,
      tenantName,
      filterType,
    } = state.filterOption;
    const { songId } = state.selectedKost;
    const { offset } = state.pagination;

    const statusBilling = getMirroredInvoiceStatus(invoiceStatus || status);

    this.$api
      .getBillListInvoices({
        year,
        month,
        songId,
        limit,
        offset,
        sortBy,
        sortOrder,
        tenantName,
        status: statusBilling,
        rentType: rentType === 'all' ? '' : rentType,
        filterType: filterType || undefined,
      })
      .then(response => {
        const { status, data } = response.data;

        if (status) {
          commit('setPaginationData', data.billings);
          commit('setPaginationTotalData', data.total);
        }
        commit('setPaginationLoading', false);
      })
      .catch(() => {
        commit('setPaginationLoading', false);
      });
  },
};
