export default async function({ store }) {
  if (store.state.goldplus.goldplusProperties.gp_status === undefined) {
    await store.dispatch('goldplus/getGoldPlusSubmissionStatus');
  }

  // eslint-disable-next-line camelcase
  const isGp2Package = store.state.goldplus.goldplusProperties?.gp_status?.key;

  if (isGp2Package === 'gp2') {
    const isHasBroadcastChatKos = store.state.broadcastChat.hasBroadcastChatKos;

    const validateUser = () => {
      if (!store.state.broadcastChat.hasBroadcastChatKos)
        window.location.href = '/?showModalKosActiveGp=true';
    };

    if (isHasBroadcastChatKos === null) {
      store.dispatch('broadcastChat/getAvailableBroadcastChat').then(() => {
        validateUser();
      });
    } else validateUser();
  } else {
    const validateUser = () => {
      const activeKos = store.getters['profile/activeKos'];
      if (!activeKos) window.location.href = '/?showModalKosActiveGp=true';
    };

    const user = store.getters['profile/user'];

    if (!Object.keys(user).length) {
      store.dispatch('profile/getProfile').then(() => {
        validateUser();
      });
    } else validateUser();
  }
}
