/**
 * Alerting b-message component from buefy.
 * This alert return promise to be called when component is closed
 */
export default ({ store }, inject) => {
  inject('alert', (type, title, message) => {
    store.commit('alert/show', { type, title, message });
    return new Promise(resolve => {
      const unsubscribe = store.subscribe((mutation, state) => {
        if (!state.alert.isActive) {
          resolve();
          unsubscribe();
        }
      });
    });
  });
};
