export default {
  postPremiumTrial() {
    return this.post('/oauth/owner/premium/trial');
  },
  submitPremiumSurvey(data) {
    return this.post('/oauth/owner/data/survey/premium', data);
  },
  getDailyActivationAllocation() {
    return this.get('/oauth/owner/premium/allocation/daily/setting');
  },
  getPromotion(propertyId) {
    return this.get(`/oauth/owner/promotion/${propertyId}`);
  },
  updatePromotion(data) {
    return this.post('/oauth/owner/promotion/', data);
  },
  getPremiumProfileData() {
    return this.get('/oauth/owner/premium/profile');
  },
  getPremiumSegment() {
    return this.get('/oauth/owner/premium/segment');
  },
  getRoomData(room) {
    return this.get(`/oauth/owner/data/list/${room.type}/${room.id}`);
  },
  getRoomStatisticsReport(data) {
    return this.get(`/oauth/stories/${data.id}/report?type=${data.time}`);
  },
  activatePremiumAllocation(data) {
    return this.post(
      `/oauth/owner/premium/allocation/${data.id}`,
      data.payload
    );
  },
  deactivatePremiumAllocation(propertyId) {
    return this.get(`/oauth/owner/premium/allocation/${propertyId}/deactivate`);
  },
  allocateDailyBudget(propertyId, data) {
    return this.post(`/oauth/owner/premium/allocation/${propertyId}`, data);
  },
  checkAbTestStatus() {
    return this.get('/oauth/owner/premium/ab-test/check');
  },
  getOwnerInvoice() {
    return this.get('/oauth/owner/premium/invoice');
  },
  getOwnerProperties(params = '') {
    return this.get(`/oauth/owner/premium/room${params}`);
  },
  activateAds(roomId, data) {
    return this.post(
      `/oauth/owner/premium/allocation/room/${roomId}?v=2`,
      data
    );
  },
  getOwnerTransactionHistory(params) {
    return this.get(
      `/oauth/owner/premium/transaction/history?status=${params}`
    );
  },
  getBalanceList() {
    return this.get('oauth/owner/premium/balance?v=2');
  },
  getGoldplusMamiadsBalance(gpCode) {
    return this.get(`oauth/owner/premium/balance?v=2&gp_code=${gpCode}`);
  },
  toggleAds(roomId, data) {
    return this.post(`/oauth/owner/premium/promote/room/${roomId}?v=2`, data);
  },
  bulkAllocation(state) {
    return this.post(`/pms_api/v3/premium/bulk/allocation?total=true`, state);
  },
  getMamiAdsStatistic(listingId) {
    return this.get(`/pms_api/v3/premium/statistic/${listingId}`);
  },
  getMamiAdsTenantList({ listingId, params }) {
    return this.get(`/pms_api/v3/premium/statistic/${listingId}/visitor`, {
      params,
    });
  },
  getMamichatChannelUrl(payload) {
    return this.post(`/pms_api/v3/chat/channel`, payload);
  },
  getHighlightedRoomType() {
    return this.get(`/pms_api/v3/premium/statistic/highlighted-room-type`);
  },
};
